import React, { useEffect, useState } from "react";
import "./ETCSiteUserSearch.css";

import Input from "../../shared/components/FormElements/Input";
import { useForm } from "../../shared/hooks/form-hook";
import Button from "../../shared/components/FormElements/Button";

const CANEricSiteUserSearch = (props) => {
  const [formState, inputHandler, setFormData] = useForm({
    searchCriteria: { value: "", isValid: true },
    searchLocation: { value: "All", isValid: true },
    searchStatus: { value: "All", isValid: true },
  });
  const { setSiteUserList, siteUserList, currentCompany } = props;
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (siteUserList) {
      let siteUserLocations = ["All"];
      if (currentCompany !== "CANEric") {
        siteUserLocations.push("ETC Field (All)");
      }
      let siteUsers = ["All"];
      siteUserList.forEach((siteUser) => {
        if (!siteUserLocations.find((item) => item === siteUser.location)) {
          if (siteUser.location) {
            siteUserLocations.push(siteUser.location);
          }
        }
        if (!siteUsers.find((item) => item === siteUser.name)) {
          if (siteUser.name) {
            siteUsers.push(siteUser.name);
          }
        }
      });

      setLocations(siteUserLocations);
    }
  }, [siteUserList]);

  const [currentStatusFilter, setCurrentStatusFilter] = useState("All");

  const statusFilterToggleHandler = async (status) => {
    setCurrentStatusFilter(status);
    inputHandler("searchStatus", status);
  };

  const [searchCriteriaFilter, setSearchCriteriaFilter] = useState("");

  const searchCriteriaFilterHandler = async () => {
    setSearchCriteriaFilter(formState.inputs.searchCriteria.value);
  };

  useEffect(() => {}, [formState.inputs.searchCriteria.value]);

  useEffect(() => {
    let list = [].concat(siteUserList);
    if (searchCriteriaFilter) {
      let criteria = searchCriteriaFilter;
      list = list.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(criteria.toLowerCase())) ||
          (item.location && item.location.toLowerCase().includes(criteria.toLowerCase())) ||
          item.status.toLowerCase().includes(criteria.toLowerCase()) 
         // ||        item.name.toLowerCase().includes(criteria.toLowerCase())
      );
    }
    if (
      formState.inputs.searchLocation.value &&
      formState.inputs.searchLocation.value !== "All"
    ) {
      list = list.filter((item) =>
        formState.inputs.searchLocation.value !== "ETC Field (All)"
          ? item.location === formState.inputs.searchLocation.value
          : item.location.includes("ETC Field")
      );
    }

    if (
      formState.inputs.searchStatus.value &&
      formState.inputs.searchStatus.value !== "All"
    ) {
      list = list.filter((item) => {
        return item.status === formState.inputs.searchStatus.value;
      });
    }

    setSiteUserList(list);
  }, [
    formState.inputs.searchStatus.value,
    formState.inputs.searchLocation.value,
    searchCriteriaFilter,
  ]);

  return (
    <div className="site-user__search-bar-content">
      <div className="site-user__search-bar-header">
        <h3>Filter</h3>
      </div>
      <div className="site-user-main-filter-content">
        <div className="site-user-sorters">
          <div className="site-user-filter-search">
            <h4>Search for a project</h4>
            <div>
              <Input
                id="searchCriteria"
                element="input"
                onInput={inputHandler}
                validators={[]}
              />

              <Button
                onClick={() => {
                  searchCriteriaFilterHandler();
                }}
              >
                Search
              </Button>
            </div>
          </div>
          {/* <div className="site-user-filter-dropdown">
            <div className="site-filter-left">
              <h4>Location</h4>

              <Input
                id="searchLocation"
                element="select"
                onInput={inputHandler}
                validators={[]}
                defaultValue={locations[0]}
              >
                {locations &&
                  locations.map((location) => <option>{location}</option>)}
              </Input>
            </div>
  
          </div> */}
        </div>
        <div className="site-user-status__toggles">
          <h4>Status</h4>
          <div
            className={`site-user-status__toggle-button ${
              currentStatusFilter === "All" && "site-user-status__all-toggled"
            }`}
            onClick={() => {
              statusFilterToggleHandler("All");
            }}
          >
            All
          </div>
          <div
            className={`site-user-status__toggle-button ${
              currentStatusFilter === "Ongoing" &&
              "site-user-status__ongoing-toggled"
            }`}
            onClick={() => {
              statusFilterToggleHandler("Ongoing");
            }}
          >
            Ongoing
          </div>
          <div
            className={`site-user-status__toggle-button ${
              currentStatusFilter === "Complete" &&
              "site-user-status__completed-toggled"
            }`}
            onClick={() => {
              statusFilterToggleHandler("Complete");
            }}
          >
            Completed
          </div>
        </div>
      </div>
    </div>
  );
};

export default CANEricSiteUserSearch;

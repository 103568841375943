import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ProjectListItem.css";

import Card from "../../../shared/components/UIElements/Card";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import TitleDescBox from "../../../landing/components/TitleDescBox";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { AuthContext } from "../../../shared/components/context/auth-context";

const ProjectListItem = (props) => {
  const auth = useContext(AuthContext);
  const token = auth.token;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();

  if (props.keywords) {
    props.keywords.sort((a, b) => a.localeCompare(b));
  }

  // useEffect(() => {
  //   const AxiosController = new AbortController();

  //   const fetchProjectThumbnail = async () => {
  //     await axios({
  //       url: `${process.env.REACT_APP_BACKEND_URL}/projects/${props.id}/files/${props.thumbnail}`,
  //       method: "GET",
  //       responseType: "blob",
  //       signal: AxiosController.signal,
  //       headers: {
  //         Authorization: "token " + token,
  //       },
  //     })
  //       .then((res) => {
  //         let img = document.getElementById(
  //           `project-list-item__image-${props.index}`
  //         );
  //         if (img) {
  //           var urlCreator = window.URL || window.webkitURL;
  //           var imageUrl = urlCreator.createObjectURL(res.data);
  //           img.src = imageUrl;
  //         }
  //       })
  //       .catch((err) => {});
  //   };
  //   try {
  //     fetchProjectThumbnail();
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   return () => {
  //     AxiosController.abort();
  //   };
  // }, []);

  const [thumbnailSRC, setThumbnailSRC] = useState();
  const fetchImageLink = async (fid, pid) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}/files/${fid}`,
        "GET",
        null,
        { Authorization: "Bearer " + token }
      );
      setThumbnailSRC(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.thumbnail && props.id) {
      fetchImageLink(props.thumbnail, props.id);
    }
  }, [props.thumbnail, props.id]);

  //Project Name limit of 22 characters
  //Description limit of 184 characters
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {props.empty ? (
        <li className="project-list-item"></li>
      ) : (
        <li className="project-list-item">
          {props.previewOnly ? (
            <img
              src={`${props.thumbnail}`}
              alt=""
              className="project-list-item__image"
            />
          ) : (
            (isLoading && (
              <div className="project-list-item__image">
                <LoadingSpinner />
              </div>
            )) || (
              <a href={`/reports/${props.id}`}>
                <img
                  id={`project-list-item__image-${props.index}`}
                  className={`project-list-item__image`}
                  alt=""
                  src={thumbnailSRC}
                />
              </a>
            )
          )}
          {}
          <div className="project-list-item__text-box">
            <TitleDescBox
              title={props.title}
              description={props.description}
              date={props.date}
              previewOnly={props.previewOnly}
              company={props.company}
              institution={props.institution}
              author={props.author}
            />
          </div>
          {/* <div className="project-list-item__keywords">
            {props.keywords &&
              props.keywords.map((keyword) => {
                return (
                  <p className="project-list-item__keyword">{`${keyword}, `}</p>
                );
              })}
          </div> */}
        </li>
      )}

      {/* 
      <div className={`project-list-item`}>
        <Card className="project-list-item__content">
          {isLoading && <LoadingSpinner asOverLay />}
          {props.previewOnly ? (
            <img
              src={`${props.thumbnail}`}
              alt=""
              className="project-list-item__image"
            />
          ) : (
            <a href={`/projects/${props.id}`}>
              <img
                className={`project-list-item__image`}
                src={`${process.env.REACT_APP_ASSET_URL}/${props.thumbnail}`}
                alt=""
              />
            </a>
          )}
          <div className="title-desc-box__mobile-background" />
          <div className="title-desc-box">
            <TitleDescBox
              title={props.title}
              description={props.description}
              date={props.date}
              previewOnly={props.previewOnly}
            />
          </div>
        </Card>
      </div> */}
    </React.Fragment>
  );
};

export default ProjectListItem;

import React, { useContext, useState } from "react";
import "./Auth.css";
import Button from "../../shared/components/FormElements/Button";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/components/context/auth-context";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useForm } from "../../shared/hooks/form-hook";
import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import "../../shared/components/FormElements/Input.css";
import { useHistory } from "react-router";

const Auth = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const [errorMessage, setErrorMessage] = useState();
  const authSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.error && responseData.message) {
        setErrorMessage(responseData.message);
      } else if (responseData.token) {
        auth.login(
          responseData.userId,
          responseData.token,
          responseData.name,
          responseData.role
        );
        history.push("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className="background" />
      <div className="auth">
        {isLoading && <LoadingSpinner asOverlay />}

        <div className="login-box">
          <h1 className="login-box__title">Log In</h1>
          <span />
          <form className="form-control" onSubmit={authSubmitHandler}>
            <div>
              <label className="input-label">Email</label>
              <Input
                id="email"
                element="input"
                label="Email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address"
                type="email"
                onInput={inputHandler}
                className="login-box-input"
              />
              <label className="input-label">Password</label>
              <Input
                element="input"
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Please enter a valid password, at least 6 characters."
                onInput={inputHandler}
                className="login-box-input"
              />
              {errorMessage && (
                <p className="login-error-message">{errorMessage}</p>
              )}
            </div>

            <div className="login-button">
              {formState.isValid}
              <Button
                type="submit"
                rounded
                size="big"
                disabled={!formState.isValid}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Auth;

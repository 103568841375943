import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import "./SideDrawer.css";

const SideDrawer = (props) => {
  const content = (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-left"
      mountOnEnter
      unmountOnExit
    >
      {/* show is true if the sideDrawer needs to be visible, false otherwise
        timeout is the duration of the animation 

        mountOnEnter - adds the contained content to the dom when transitioning in 
        unmountOnExit - removes the contained content from the dom when transitioning out
    */}
      <aside className="side-drawer" onClick = {props.onClick}>{props.children}</aside>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;

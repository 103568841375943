import React, { useState } from "react";
import { Link } from "react-router-dom";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import "./MainNavigation.css";
import { isMobile } from "react-device-detect";

import CerinLogo from "../../../assets/images/logos/cerin logo/CERIN Logo.png";

import CerinLogoMobile from "../../../assets/images/logos/cerin logo/Cerin-Logo-shape-4-215x88.png";
import { AuthContext } from "../context/auth-context";
import { useContext } from "react";
const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const user = auth.userName;
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <div className="main-navigation">
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      {
        <SideDrawer show={drawerIsOpen}>
          <nav className="main-navigation__drawer-nav">
            <NavLinks closeDrawerHandler={closeDrawerHandler} />
          </nav>
        </SideDrawer>
      }

      <MainHeader>
        <div className="main-navigation__title">
          <Link className="cerin-header__logo" to="/">
            <img
              className="cerin-logo__image"
              src={isMobile ? CerinLogoMobile : CerinLogo}
              alt="cerin-logo"
            />
          </Link>
        </div>
        <nav className="main-navigation__header-nav">
          {auth.isLoggedIn && <p className="user-welcome">Welcome, {user}</p>}
          <NavLinks
            setShowSelectCompanyModal={props.setShowSelectCompanyModal}
          />
        </nav>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
      </MainHeader>
    </div>
  );
};

export default MainNavigation;


import {useState, useCallback, useEffect} from 'react'

let logoutTimer;


export const useAuth = () => {
    const [token, setToken] = useState();
    const [userName, setUserName] = useState();
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userId, setUserId] = useState();
    const [userRole, setUserRole] = useState();
    const login = useCallback((uid,token, name, userRole, expirationDate) => {
      setToken(token);
      setUserId(uid);
      setUserName(name);
      setUserRole(userRole);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60); //current date + 1 hr
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          userName: name,
          userRole:userRole,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    }, []);
  
    const logout = useCallback(() => {

      setToken(null);
      setTokenExpirationDate(null);
      setUserId(null);
      setUserName(null);
      setUserRole(null);
      localStorage.removeItem("userData");
    }, []);
  
    useEffect(()=>{
      if(token && tokenExpirationDate){
        const remainingTime = tokenExpirationDate.getTime() - new Date().getTime(); //calcualtes the remaining time for the token before expiring
        logoutTimer = setTimeout(logout, remainingTime);
      }
      else{
        clearTimeout(logoutTimer);
      }
    },[token,logout, tokenExpirationDate])
  
    useEffect(() => {
      //only runs the "first" time app is rendered
      const storedData = JSON.parse(localStorage.getItem("userData"));

      if (
        storedData &&
        storedData.token &&
        new Date(storedData.expiration) > new Date()
      ) {
        //checks if stored data and token exists, and also checks if the storeddata's token expiration date is not
        //past the current date
        login(storedData.userId, storedData.token, storedData.userName, storedData.userRole);
      }
    }, [login]);

    return {token,login,logout,userId, userName, userRole};
}

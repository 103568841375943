import "./ContactListForm.css";
import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useForm } from "../../../shared/hooks/form-hook";
import Input from "../../../shared/components/FormElements/Input";
import { uniqueId } from "lodash";

const ContactListForm = (props) => {
  const contactList = props.formState.inputs.contacts ? props.formState.inputs.contacts.value : [];

  const [formState, inputHandler] = useForm(
    {
      contactType: { value: "", isValid: false },
      contactValue: { value: "", isValid: false },
    },
    false
  );

  const addContactHandler = (event) => {
    event.preventDefault();
    if (
      formState.inputs.contactType.value.toString().replace(/\s/g, "") &&
      formState.inputs.contactValue.value.toString().replace(/\s/g, "")
    ) {
      props.inputHandler(
        "contacts",
        contactList.concat({
          contactType: formState.inputs.contactType.value,
          contactValue: formState.inputs.contactValue.value,
        }),
        true
      );
    }
  };

  const removeContactHandler = (contactIndex) => {
    props.inputHandler(
      "contacts",
      contactList.filter((current, index) => contactIndex !== index),
      true
    );
  };

  return (
    <React.Fragment>
      <div className="contact-list-form">
        <h1>Contact List</h1>

        <div className="contact-list-input">
          <Input
            element="input"
            maxLength = {40}
            id="contactType"
            value=""
            onInput={inputHandler}
            validators={[]}
            className="contact-type-input"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addContactHandler(e);
              }
            }}
          />
          <Input
            element="input"
            id="contactValue"
            maxLength = {40}
            value=""
            onInput={inputHandler}
            validators={[]}
            className="contact-value-input"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addContactHandler(e);
              }
            }}
          />

          <button
            type="button"
            onClick={addContactHandler}
            disabled={
              !formState.inputs.contactValue.value ||
              !formState.inputs.contactType.value
            }
          >
            Add Contact
          </button>
        </div>
        <div className="contact-list">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Contact Type</TableCell>
                  <TableCell align="right">Contact Value</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList.map((contact, index) => (
                  <TableRow
                    key={uniqueId()}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {contact.contactType}
                    </TableCell>
                    <TableCell align="right">{contact.contactValue}</TableCell>
                    <TableCell align="right">
                      <button
                        type="button"
                        onClick={() => {
                          removeContactHandler(index);
                        }}
                      >
                        x
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactListForm;

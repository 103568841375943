import React from "react";
import InstitutionBox from "../components/InstitutionBox";
import FourBLUELogo from "../../assets/images/logos/site-users/4-blue-energy.png";
import AltoMaxxLogo from "../../assets/images/logos/site-users/alto-maxx-logo.webp";
import ClearRushLogo from "../../assets/images/logos/site-users/clear-rush-logo.png";
import KiniticsLogo from "../../assets/images/logos/site-users/kinitics-automation.webp";
import MarathonLogo from "../../assets/images/logos/site-users/marathon-compression-logo.webp";
import PackairLogo from "../../assets/images/logos/site-users/packair-industries.webp";
import KathairosLogo from "../../assets/images/logos/site-users/Kathairos_Solutions.jpg";
import SRCLogo from "../../assets/images/logos/site-users/saskatchewan research council.svg";
import TRIGLogo from "../../assets/images/logos/site-users/TRIG.jpg";
import VentBusterLogo from "../../assets/images/logos/site-users/Ventbuster-Instruments-Feature-Logo-400x270-1.png";
import FEMPLogo from "../../assets/images/logos/site-users/femp_logo.jpg";
import "./SiteUsers.css";
const SiteUsers = (props) => {
  //TODO automate this
  const etcSiteUsers = [
    {
      name: "Packair Industries Inc",
      logo: PackairLogo,
      reports: [
        {
          id: "637fc8cf0abd33a616af512c",
          name: "Packair Compressor Testing",
          status: "",
        },
      ],
    },
    {
      name: "FEMP",
      logo: FEMPLogo,
      reports: [
        {
          id: "63fe1dac3be9bf16f89eb6cc",
          name: "FEMP-EA (datasets)",
          status: "",
        },
      ],
    },
    {
      name: "Marathon Compression",
      logo: MarathonLogo,
      reports: [
        {
          id: "63c96dd06313f25a37bada20",
          name: "Air Compressor, Marathon KL8",
          status: "",
        },
      ],
    },
    {
      name: "4BLUE Energy Services Inc.",
      logo: FourBLUELogo,
      reports: [
        {
          id: "63fe8cb73be9bf16f89ebae5",
          name: "4BLUE OGI Camera Test",
          status: "",
        },
      ],
    },
    {
      name: "AltoMaxx Methane Sensor ",
      logo: AltoMaxxLogo,
      reports: [
        {
          id: "640a3bcba92438e15cb6dbdf",
          name: "AltoMaxx Methane Sensor ",
          status: "",
        },
      ],
    },
    {
      name: "TransRail Innovation Inc.",
      logo: TRIGLogo,
      reports: [
        {
          id: "64497883bea5bdf6ad44ae28",
          name: "TransRail Innovation PoC ",
          status: "",
        },
      ],
    },
    {
      name: "Kinitics Automation, Ltd.",
      logo: KiniticsLogo,
      reports: [
        {
          id: "63fe1dac3be9bf16f89eb6cc",
          name: "Kinitics ETC Phase1 Report",
          status: "",
        },
      ],
    },
  ];

  const canEricSiteUsers = [
    {
      name: "Kathairos",
      logo: KathairosLogo,
      reports: [
        {
          id: "627d136974d81cf49d0a925d",
          name: "Kathairos Field Trial",
          status: "",
        },
      ],
    },
    {
      name: "Clear Rush Co.",
      logo: ClearRushLogo,
      reports: [
        {
          id: "62902ae765c33a8f917d9155",
          name: "TCE Combustor Report",
          status: "",
        },
      ],
    },
    {
      name: "VentBuster Instruments Inc",
      logo: VentBusterLogo,
      reports: [
        {
          id: "62b28aa32207c65652107d57",
          name: "Ventsentinel Flow Metering",
          status: "",
        },
      ],
    },
    {
      name: "Saskatchewan Research Council",
      logo: SRCLogo,
      reports: [
        {
          id: "62b28b967f9ac0318b2c1ae7",
          name: "CanERIC Combuster Scoping ",
          status: "",
        },
        {
          id: "62b28b9b7f9ac0318b2c1b02",
          name: "Catalytic Oxidizer Test",
          status: "",
        },
      ],
    },
  ];

  return (
    <div className="site-users__content">
      <p className="site-users__title">Site Users</p>
      <InstitutionBox institutionName="ETC" siteUsers={etcSiteUsers} />
      <InstitutionBox institutionName="CanERIC" siteUsers={canEricSiteUsers} />
    </div>
  );
};

export default SiteUsers;

import React, { useContext } from "react";
import { AuthContext } from "../../shared/components/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "../pages/Projects.css";

const ETCSiteUsers = (props) => {
  const {
    filteredSiteUserList,
    completeStatus,
    OngoingStatus,
    navigateToReportHandler,
    isLoading
  } = props;
  const auth = useContext(AuthContext);
  const token = auth.token;

  return (
    <div className="project-cards-wrap">
      {filteredSiteUserList.length === 0 && !isLoading && <h2>No Results</h2>}
      {filteredSiteUserList &&
        filteredSiteUserList.map((siteUser) => (
          <div className="project-card__container container-etc">
            <div
              className={`project-card ${
                siteUser.reportId && "project-card__has-report"
              }`}
              onClick={() => {
                if (siteUser.reportId) {
                  navigateToReportHandler(siteUser.reportId);
                }
              }}
            >
              <div className="project-card-top">
                <div className="site-user__logo">
                  {console.log(siteUser.logo)}
                  {siteUser.logo ? (
                    <img src={siteUser.logo} alt={siteUser.siteUser} />
                  ) : (
                    <h2>{siteUser.siteUser || siteUser.name}</h2>
                  )}
                </div>
              </div>
              <hr className="project-card-split"></hr>
              <div className="project-card-bottom">
                <div className="project-card-bottom-wrap">
                  <div className="site-user__description">
                    {siteUser.description}
                  </div>
                  <div className="card-bottom-info">
                    <div className="location-framework">
                      <div className="site-user__location">
                        {siteUser.location}
                      </div>
                    </div>
                    <div className="site-user__status">
                      {siteUser.status === "Complete" ? (
                        <img
                          className="project-status__icon"
                          src={completeStatus}
                          alt="Complete"
                        />
                      ) : (
                        <img
                          className="project-status__icon"
                          src={OngoingStatus}
                          alt="Ongoing"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ETCSiteUsers;

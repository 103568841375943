import React from "react";
import "./InstitutionBox.css";
import SiteUser from "./SiteUser";
const InstitutionBox = (props) => {
  const { institutionName, siteUsers } = props;

  return (
    <div className="site-user__institution__box">
      {siteUsers && siteUsers.map((item) => <SiteUser siteUser={item} />)}
    </div>
  );
};

export default InstitutionBox;

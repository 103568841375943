import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import Backdrop from "./Backdrop";

import "./Modal.css";

const ModalOverlay = (props) => {
  const content = (
    <div className="modal-box">
      <div 
        className={`modal ${props.className} ${props.small ? "small" : ""} ${
          props.error ? "errora" : ""
        }`}
        style={props.style}
      >
        <header className={`modal__header ${props.headerClass}`}>
          <h2>{props.header}</h2>
        </header>
        <form
          onSubmit={
            props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
          }
        >
          <div
            className={`modal__content ${props.contentClass} ${
              props.children ? "" : "content-empty"
            }`}
          >
            {props.children}
          </div>
          <footer className={`modal__footer ${props.footerClass}`}>
            {props.footer}
          </footer>
        </form>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
        {/* takes in props passed to model and forwards them to modelOverlay. 
        Sends footers headers etc to modelOverlay     ... is the spread operator. It takes key-value pairs and spreads them to target     */}
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;

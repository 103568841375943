import React, { useContext, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { uniqueId } from "lodash";

import "./ViewMemberList.css";
import { AuthContext } from "../../../shared/components/context/auth-context";
import Modal from "../../../shared/components/UIElements/Modal";
import Button from "../../../shared/components/FormElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import AddMembers from "./AddMembers";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { isMobile } from "react-device-detect";

const ViewMemberList = (props) => {
  const auth = useContext(AuthContext);
  let memberList = props.userList; //.filter((u) => u.user.id !== auth.userId);
  const [roleToChange, setRoleToChange] = useState();
  const [userToChange, setUserToChange] = useState();
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const token = auth.token;
  const updateUserMemberRoleHandler = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/projects/${props.projectId}/members/${userToChange}`,
        "PATCH",
        JSON.stringify({ role: roleToChange }),
        { Authorization: "Bearer " + token, "Content-Type": "application/json" }
      );
      let list = [...props.userList]; //shallow copy
      let item = list.find((i) => i.member.id === userToChange);

      item.memberRole = roleToChange;

      props.setUserList(list);
      setShowRoleModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const userMemberRole = props.userMemberRole;

  const closeRoleModalHandler = () => {
    setShowRoleModal(false);
  };

  const openAddModalHandler = () => {
    setShowAddModal(true);
  };
  const closeAddModalHandler = () => {
    setShowAddModal(false);
  };

  const addMemberHandler = async (user, role) => {
    props.setUserList(
      props.userList.concat({
        member: user,
        memberRole: props.projectType === "Public" ? "Contributor" : "Member",
      })
    );
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/projects/${props.projectId}/members/${user.id}`,
        "POST",null ,
        { Authorization: "Bearer " + token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const openRemoveModalHandler = () => {
    setShowRemoveModal(true);
  };
  const closeRemoveModalHandler = () => {
    setShowRemoveModal(false);
  };

  const removeMemberHandler = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/projects/${props.projectId}/members/${userToChange}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + token }
      );
      const list = props.userList.filter((item) => item.id !== userToChange);
      props.setUserList(list);
      setShowRemoveModal(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <form className="view-current-members-list">
        <h1>Current Members</h1>
        {!isMobile && (
          <Button
            type="button"
            className="add-members__button"
            onClick={openAddModalHandler}
            style={
              //only display button if authorized
              userMemberRole === "Contributor" || userMemberRole === "Author"
                ? {}
                : { display: "none" }
            }
          >
            Add Members
          </Button>
        )}
        <Modal
          show={showRoleModal}
          onCancel={closeRoleModalHandler}
          header="Are you sure you want to change this member's role?"
          contentClass="role-modal__content"
        >
          <Button onClick={updateUserMemberRoleHandler}>Yes</Button>
          <Button onClick={closeRoleModalHandler}>CANCEL</Button>
        </Modal>
        <Modal
          show={showAddModal}
          onCancel={closeAddModalHandler}
          header="Add a member"
          contentClass="add-modal__content"
          footerClass="add-modal__footer"
          footer={<Button onClick={closeAddModalHandler}>Close</Button>}
        >
          <AddMembers
            userList={props.userList}
            addMemberHandler={addMemberHandler}
          />
        </Modal>
        <Modal
          show={showRemoveModal}
          onCancel={closeRemoveModalHandler}
          header="Are you sure you want to remove this member?"
          contentClass="remove-modal__content"
          footerClass="remove-modal__footer"
          footer={
            <React.Fragment>
              <Button onClick={removeMemberHandler}>Yes</Button>
              <Button onClick={closeRemoveModalHandler}>Cancel</Button>
            </React.Fragment>
          }
          small
        ></Modal>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Company</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memberList.map((member) => (
                <TableRow
                  key={member ? member.member.name : uniqueId()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {member.member.name}
                  </TableCell>
                  <TableCell align="right">{member.member.email}</TableCell>
                  <TableCell align="right">{member.member.company}</TableCell>
                  <TableCell align="right">
                    <div>
                      {/**
                       *  If the user's member role is Author, show member role edit. Otherwise show only the role name.
                       */}
                      {userMemberRole === "Author" &&
                      member.memberRole.roleName !== "Author" ? (
                        <select
                          defaultValue={member.memberRole.roleName}
                          disabled={
                            props.projectType === "Public" ||
                            props.projectType === "" ||
                            (member.memberRole.roleName === "Contributor" &&
                              userMemberRole !== "Author")
                          }
                          onChange={(event) => {
                            setRoleToChange(event.target.value);
                            setUserToChange(member.member.id);
                            setShowRoleModal(true);
                          }}
                        >
                          <option>Member</option>
                          <option>Contributor</option>
                        </select>
                      ) : (
                        member.memberRole.roleName
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={
                      //only display button if authorized
                      userMemberRole === "Contributor" ||
                      userMemberRole === "Author"
                        ? {}
                        : { display: "none" }
                    }
                  >
                    {member.memberRole.roleName !== "Author" && !isMobile && !(userMemberRole === "Contributor" && member.memberRole.roleName === "Contributor")? (
                      <button
                        type="button"
                        onClick={() => {
                          openRemoveModalHandler();
                          setUserToChange(member.member.id);
                        }}
                        disabled={
                          (userMemberRole !== "Contributor" &&
                            userMemberRole !== "Author") || // member is not Contributor or Author
                          member.memberRole.roleName === "Author" || // member role name listed is Author
                          (member.memberRole.roleName === "Contributor" &&
                            userMemberRole === "Contributor") // member role name listed is Contributor and user is Contributor
                        }
                      >
                        Remove Member
                      </button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal onClear={clearError} error={error} />
    </div>
  );
};

export default ViewMemberList;

import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../components/context/auth-context";
export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  const activeHttpRequests = useRef([]);
  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}, noJSON) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController(); // logic run when a request is sent for a component that is no longer on screen
      //TODO 
      //Set up abort ctrl for axios
      activeHttpRequests.current.push(httpAbortCtrl);
      
      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        let responseData;
        if (noJSON) {
          responseData = response;
          if (!response.ok) {
            throw new Error(responseData.message);
          }
        } else {
          responseData = await response.json();

          if (!response.ok) {
            throw new Error(responseData.message);
          }
        }

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err.message);
        console.log(err);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );
  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    // logic run when a request is sent for a component that is no longer on screen
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);
  return { isLoading, error, sendRequest, clearError, setError };
};

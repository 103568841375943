import React, { useContext, useEffect, useRef, useState } from "react";
import "./AttachmentsBox.css";
import AttachmentsBoxItem from "./AttachmentsBoxItem";

import { uniqueId } from "lodash";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/components/context/auth-context";
import Modal from "../../../shared/components/UIElements/Modal";
import Button from "../../../shared/components/FormElements/Button";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import Input from "../../../shared/components/FormElements/Input";
import { VALIDATOR_EMAIL } from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";

const AttachmentsBox = (props) => {
  const {
    newFiles,
    setNewFiles,
    setExistingFiles,
    existingFiles,
    isLoading,
    removeAttachmentHandler,
  } = props;
  const auth = useContext(AuthContext);
  const token = auth.token;
  const uid = auth.userId;

  const [attachmentToRemove, setAttachmentToRemove] = useState();
  const [showRemoveAttachmentConfirm, setShowAttachmentConfirm] =
    useState(false);

  const openRemoveAttachmentConfirmHandler = (item) => {
    setAttachmentToRemove(item);
    setShowAttachmentConfirm(true);
  };

  const closeRemoveAttachmentConfirmHandler = () => {
    setAttachmentToRemove();
    setShowAttachmentConfirm(false);
  };

  const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  //TODO
  //Use display:grid to align box items

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {/* <Modal
        show={showRemoveAttachmentConfirm}
        header="Remove this attachment?"
        contentClass="remove-attachment-confirm-modal__content"
        footerClass="remove-attachment-confirm-modal__footer"
        footer={
          <React.Fragment>
            <Button
              onClick={() => {
                closeRemoveAttachmentConfirmHandler();
              }}
            >
              Confirm
            </Button>

            <Button onClick={closeRemoveAttachmentConfirmHandler}>
              Cancel
            </Button>
          </React.Fragment>
        }
      ></Modal> */}
      <ul className="attachments-box box-list">
        {props.existingItems &&
          props.existingItems.map((f, index) => {

            console.log(props.pid)
            return (
              <AttachmentsBoxItem
                userMemberRole={props.userMemberRole}
                fileName={f.file.name}
                remover={openRemoveAttachmentConfirmHandler}
                uid={uid}
                fid={f.fid}
                type={props.type}
                item={f.file}
                index={index}
                key={index}
                pid={props.pid}
                projectViewType={props.projectType}
                projectName={props.projectName}
                projectType={props.projectType}
                downloadEmail={props.downloadEmail}
                changeShowEmailFormHandler={props.changeShowEmailFormHandler}
                removeAttachmentHandler={removeAttachmentHandler}
              />
            );
          })}
        {props.newItems.map((f, index) => {
          console.log(f);
          return (
            <AttachmentsBoxItem
              userMemberRole={props.userMemberRole}
              remover={openRemoveAttachmentConfirmHandler}
              uid={uid}
              fileName={f.fileName}
              type={props.type}
              item={f}
              fid={f.id}
              index={index}
              key={index}
              pid={props.pid}
              projectViewType={props.projectType}
              projectName={props.projectName}
              projectType={props.projectType}
              downloadEmail={props.downloadEmail}
              changeShowEmailFormHandler={props.changeShowEmailFormHandler}
              removeAttachmentHandler={removeAttachmentHandler}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default AttachmentsBox;

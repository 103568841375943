import React, { useContext, useEffect, useState } from "react";
import "./PDFViewer.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isMobile } from "react-device-detect";
import Button from "../FormElements/Button";
import download from "downloadjs";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/components/context/auth-context";
import axios from "axios";
import { saveAs } from "file-saver";

const PDFViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const token = auth.token;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const [PDF, setPDF] = useState();

  // useEffect(() => {

  //   const fetchPDF = async () => {
  //     await axios({
  //       url: `${process.env.REACT_APP_BACKEND_URL}/projects/${props.pid}/files/${props.pdf}`,
  //       method: "GET",
  //       responseType: "blob",
  //       headers: {
  //         Authorization: "token " + token,
  //       },
  //     }).then((res) => {
  //       setPDF(res.data);
  //       // var urlCreator = window.URL || window.webkitURL;
  //       // var pdfUrl = urlCreator.createObjectURL(res.data);
  //     });
  //   };

  //   if (!props.pdfData) {
  //     fetchPDF();
  //   } else {
  //     setPDF(props.pdfData);
  //   }
  // }, [token, props.pdfData]);
  const [thumbnailSRC, setThumbnailSRC] = useState();
  const fetchPDF = async (fid, pid) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}/files/${fid}`,
      "GET",
      null,
      { Authorization: "Bearer " + token }
    );
    setPDF(responseData);
    console.log(responseData);
  };
  useEffect(() => {
    if (!props.pdfData) {
      fetchPDF(props.pdf, props.pid);
    } else {
      setPDF(props.pdfData);
    }
  }, [token, props.pdfData]);

  const downloadPDFHandler = async (event) => {
    event.preventDefault();
    let file;
    let fileName;
    const token = auth.token;
    if (!props.downloadEmail) {
      //console.log(props.downloadEmail);
      props.changeShowEmailFormHandler();
    } else {
      try {
        await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/projects/${props.pid}/files/${props.pdf}/download`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: "token " + token,
            Email: props.downloadEmail,
          },
          onDownloadProgress: (p) => {
            //console.log(p);
            const percentCompleted = Math.round((p.loaded * 100) / p.total);
            //console.log(`${percentCompleted}% downloaded`);
          },
        }).then((res) => {
          res.headers.get("filename");
          fileName = res.headers.get("filename");
          file = res.data;

          try {
            saveAs(file, fileName);
          } catch (err) {
            console.log();
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="project-box view-pdf">
      {/* <p className="pageNum pageNum-top">
        <button
          disabled={pageNumber - 1 <= 0}
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
          type="button"
        >
          Prev
        </button>
        Page {pageNumber} of {numPages}
        <button
          disabled={pageNumber + 1 > numPages}
          onClick={() => {
            setPageNumber(1 + pageNumber);
          }}
          type = "button"
        >
          Next
        </button>
      </p> */}
      <div className="pdf-document">
        <Document file={{ url: PDF }} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            scale={isMobile ? 0.6 : 1.3}
            /* maybe dynamic scaling here*/ pageNumber={pageNumber}
          />
        </Document>
      </div>
      <p className="pageNum pageNum-bottom">
        {props.projView && (
          <Button onClick={downloadPDFHandler}>Download PDF</Button>
        )}

        {/* <button
          disabled={pageNumber - 1 <= 0}
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
          type="button"
        >
          Prev
        </button>
        Page {pageNumber} of {numPages}
        <button
          disabled={pageNumber + 1 > numPages}
          onClick={() => {
            setPageNumber(1 + pageNumber);
          }}
          type="button"
        >
          Next
        </button> */}
      </p>{" "}
    </div>
  );
};

export default PDFViewer;

import React, { useContext, useState } from "react";
import { AuthContext } from "../context/auth-context";
import "./NavDropdown.css";
import { NavLink, useHistory } from "react-router-dom";
const NavDropdown = props => {
  const auth = useContext(AuthContext);
  const [isToggled, setIsToggled] = useState(false);
  const history = useHistory();
  const closeDrawer = props.closeDrawerHandler;


  const logoutHandler = () => {
    auth.logout();
    history.push("/login");
  };

  const openDropdownHandler = () => {
    setIsToggled(true);
  };
  const closeDropdownHandler = () => {
    setIsToggled(false);
  };
  return (
    <div className="dropdown">
          <div className="dropdown-header">
            <button
              onClick={isToggled ? closeDropdownHandler : openDropdownHandler}
            >
              Account <i className="arrow down"></i>
            </button>
          </div>
          {isToggled && (
            <ul className="dropdown-list" onClick = {closeDropdownHandler}>
              <li>
                <NavLink to="/myprojects">My Projects</NavLink>
              </li>
              <li>
                <NavLink to="/account">Change Password</NavLink>
              </li>
              {auth.userRole === "Administrator" && <li>
                <NavLink to="/admin">Admin</NavLink>
              </li>}
              <li>
                <button onClick={logoutHandler}>Log out</button>
              </li>
            </ul>
          )}
        </div>
     
      )
  
};
export default NavDropdown;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../shared/components/context/auth-context";
import { isMobile } from "react-device-detect";
import DownloadIcon from "../../../assets/images/icons/download-icon.png";
import FileIcon from "../../../assets/images/icons/file-icon.png";
import download from "downloadjs";
import axios from "axios";
import { saveAs } from "file-saver";

import "./AttachmentsBoxItem.css";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
const AttachmentsBoxItem = (props) => {
  const auth = useContext(AuthContext);
  const { removeAttachmentHandler, item, index, fid } = props;

  const token = auth.token;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  let url;
  let fileExtension;
  try {
    if (props.type === "view") {
      //url = props.item.replaceAll("\\", "/").split("/");
      // fileExtension =
      //   props.item.name.split(".")[props.item.name.split(".").length - 1];
    }
  } catch (err) {
    console.log(err);
  }

  useEffect(() => {
    // const AxiosController = new AbortController();
    // //IMPORTANT TODO
    // //Implement presigned URL AWS S3 upload/download

    let img = document.getElementById("project-attachment-image" + props.index);
    // const fetchAttachment = async () => {
    //   axios({
    //     url: `${process.env.REACT_APP_BACKEND_URL}/projects/${props.pid}/files/${props.item.id}`,
    //     method: "GET",
    //     responseType: "blob",
    //     signal: AxiosController.signal,
    //     headers: {
    //       Authorization: "token " + token,
    //     },
    //   })
    //     .then((res) => {
    //       if (img) {
    //         var urlCreator = window.URL || window.webkitURL;
    //         var imageUrl = urlCreator.createObjectURL(res.data);
    //         img.src = imageUrl;
    //       }
    //       setFileName(props.item.fileName);
    //     })
    //     .catch((err) => {});
    // };
    // if (props.type === "view") {
    //   if (
    //     props.item &&
    //     (props.item.fileName.endsWith(".png") ||
    //       props.item.fileName.endsWith(".jpg"))
    //   ) {
    //     try {
    //       fetchAttachment();
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   } else {
    //     if (img) {
    //       img.src = FileIcon;
    //       setFileName(props.item.fileName);
    //     }
    //   }
    // } else
    if (props.type === "input") {
      if (props.item.type.replaceAll("\\", "/").split("/")[0] === "image") {
        // if file is an image input
        const fileReader = new FileReader();
        //executes after file is read
        fileReader.onload = () => {
          if (img) {
            img.src = fileReader.result;
            setFileName(props.item.name);
          }
        };
        //Reads data and triggers onload
        fileReader.readAsDataURL(props.item);
      } else {
        if (img) {
          img.src = FileIcon;
          setFileName(props.item.fileName);
        }
      }
    }

    // return () => {
    //   AxiosController.abort();
    // };
  }, [props.type, props.item]);

  const [fileName, setFileName] = useState("");

  const [loadingMessage, setLoadingMessage] = useState();
  const [loadingProgress, setLoadingProgress] = useState();

  const downloadHandler = async (event) => {
    event.preventDefault();
    if (props.downloadEmail) {
      let file;
      let fileName;
      const token = auth.token;

      try {
        setLoadingMessage("Downloading from the server...");
        await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/projects/${props.pid}/files/${props.fid}/download`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: "token " + token,
            Email: props.downloadEmail,
          },
          onDownloadProgress: (p) => {
            const percentCompleted = Math.round((p.loaded * 100) / p.total);
            setLoadingProgress(percentCompleted);
          },
        }).then((res) => {
          res.headers.get("filename");
          fileName = res.headers.get("filename");
          file = res.data;
          setLoadingMessage();
          setLoadingProgress();
          try {
            saveAs(file, fileName);
          } catch (err) {
            console.log();
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      props.changeShowEmailFormHandler();
    }
  };

  const [thumbnailSRC, setThumbnailSRC] = useState();
  const fetchImageLink = async (fid, pid) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}/files/${fid}`,
      "GET",
      null,
      { Authorization: "Bearer " + token }
    );
    console.log(responseData);
    setThumbnailSRC(responseData);
  };

  useEffect(() => {
    console.log(props);
    if ((props.fid, props.pid)) {
      fetchImageLink(props.fid, props.pid);
    }
  }, [props.fid, props.pid]);

  useEffect(() => {
    setFileName(props.fileName);
  }, [props.fileName]);

  useEffect(() => {}, [props.item, props.type]);

  useEffect(() => {}, [thumbnailSRC]);

  return (
    <li className="attachments-box-item">
      {loadingProgress && (
        <LoadingSpinner
          asOverlay
          message={loadingMessage}
          loadingProgress={loadingProgress}
        />
      )}
      {props.type === "input" &&
        !isMobile &&
        (props.userMemberRole === "Author" ||
          props.userMemberRole === "Contributor") && (
          <button
            className="attachment-remove-button"
            onClick={() => {
              return removeAttachmentHandler(item, fid, index);
            }}
          >
            x
          </button>
        )}
      <div className="image-box">
        <img
          id={`project-attachment-image${props.index}`}
          className="file-image"
          alt="file icon"
          src={thumbnailSRC}
        />
      </div>

      <p className="attachment-file-name">
        {props.type === "view" && (
          <div className="download-link">
            {props.projectViewType === "Review" ? (
              `${fileName}`
            ) : (
              <p
                className="attachment__download-link"
                onClick={downloadHandler}
              >
                {fileName}
                <img className="download-icon" src={DownloadIcon} alt="" />
              </p>
            )}
          </div>
        )}
        {props.type === "input" && <React.Fragment>{fileName} </React.Fragment>}
      </p>
    </li>
  );
};

export default AttachmentsBoxItem;

import React from "react";
import Input from "../../../shared/components/FormElements/Input";

import "./ProjectPreviewForm.css";
import ImageUpload from "../../../shared/components/FormElements/ImageUpload";
import {
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
} from "../../../shared/util/validators";
const ProjectPreviewForm = (props) => {
  return (
    <div className="project-box">
      {/* <div className="project-box">
        <div className="project-date">
          <h1>Project Date</h1>

          <Input
            id="projectDate"
            className="project-date__input"
            type="date"
            element="input"
            onInput={props.inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
          />
        </div>
      </div> */}
      {/* <span className="page-separator" /> */}


      <h1>Project Preview Card</h1>

        <div className="project-preview-form__text">
          <Input
            id="projectName"
            element="input"
            className="project-name"
            onInput={props.inputHandler}
            validators={[]}
            disabled={props.edit}
            defaultValue={props.defaultValues.projectName}
            placeholder="Enter project name here"
          ></Input>
          {/* <Input
          id="projectDesc"
          element="textareaautosize"
          className="project-desc"
          placeholder="Enter project description here"
          maxLength={174}
          onInput={props.inputHandler}
          validators={[VALIDATOR_MAXLENGTH(184)]}
          defaultValue={props.defaultValues.description}
        ></Input> */}
        </div>

        <div className="project-preview-form__image">
          <ImageUpload
            id="thumbnail"
            className="image-upload"
            onInput={props.inputHandler}
            setPreview={props.previewChangeHandler}
            defaultValue={props.defaultValues.thumbnail}
          />
        </div>
        
      
    </div>
  );
};

export default ProjectPreviewForm;

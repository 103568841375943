import Moment from "react-moment";
import React from "react";
import "./TitleDescBox.css";
const TitleDescBox = (props) => {
  //  const title = "wwwwwwwwwwwwwwwwwwwwwwwwwwwwww"; //max 31 chars
  // const desc = "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww"//max 119 chars
  return (
    <div className="project-card__box">
      <p
        className={`project-card__title ${
          (props.title.length > 70 && "project-card__title-very-long") ||
          (props.title.length > 40 && "project-card__title-long")
        }`}
      >
        {props.title}
      </p>
      {/* <div className="project-card__date">
        {!props.previewOnly || (props.previewOnly && !!props.date) ? (
          <React.Fragment>
            Project Date:&nbsp;
            <Moment format="YYYY/MM/DD">{props.date}</Moment>
          </React.Fragment>
        ) : (
          "Select a Date"
        )}
      </div> */}
      {/* {props.company === "4BLUE Inc." && (
        <p className="project-card__author">Author: {props.author}</p>
      )} */}
      <br />
      {/* {props.institution && (
        <p className="project-card__institution-company">
          <React.Fragment>
            In collaboration of: <br />
            <b>{props.institution}</b> and <b>{props.company}</b>
          </React.Fragment>
        </p>
      )} */}
    </div>
  );
};

export default TitleDescBox;

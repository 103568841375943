import React, { useRef, useState, useEffect } from "react";
import PDFViewer from "../UIElements/PDFViewer";

import "./PDFUpload.css";
//import { Viewer } from "@react-pdf-viewer/core";
//import { Worker } from "@react-pdf-viewer/core";

const PDFUpload = (props) => {
  const filePickerRef = useRef();
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    }; //executes after file is read
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }

    props.onInput(props.id, pickedFile, fileIsValid);
  };

  return (
    //<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
    <div className="pdf-upload-box">
      <input
        id={props.id}
        ref={filePickerRef}
        type="file"
        accept=".pdf"
        onChange={pickedHandler}
      />
      <div className="pdf-preview">
        {previewUrl ? (
          <div className="pdf-preview__picked">
            <PDFViewer pdfData={previewUrl} />
          </div>
        ) : (
          <div className="pdf-preview__empty">Preview area</div>
        )}
      </div>
    </div>
    //  </Worker>               <Viewer fileUrl={previewUrl} />
  );
};

export default PDFUpload;

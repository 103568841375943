import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import "./Projects.css";
import Modal from "../../shared/components/UIElements/Modal";
import FourBLUELogo from "../../assets/images/logos/site-users/4-blue-energy.png";
import AltoMaxxLogo from "../../assets/images/logos/site-users/alto-maxx-logo.webp";
import ClearRushLogo from "../../assets/images/logos/site-users/clear-rush-logo.png";
import KiniticsLogo from "../../assets/images/logos/site-users/kinitics-automation.webp";
import MarathonLogo from "../../assets/images/logos/site-users/marathon-compression-logo.webp";
import PackairLogo from "../../assets/images/logos/site-users/packair-industries.webp";
import TRIGLogo from "../../assets/images/logos/site-users/TRIG.png";
import QUBELogo from "../../assets/images/logos/site-users/qube.png";
import KUVALogo from "../../assets/images/logos/site-users/kubacloud.png";
import LSILogo from "../../assets/images/logos/site-users/lidar-services-international-inc.png";
import mCloudLogo from "../../assets/images/logos/site-users/mcloud.png";
import TRICANLogo from "../../assets/images/logos/site-users/trican.png";
import SpartanLogo from "../../assets/images/logos/site-users/spartan-controls.png";
import WestGenLogo from "../../assets/images/logos/site-users/westgen_logo.png";
import MFlowLogo from "../../assets/images/logos/site-users/M-Flow-master-logo.svg";
import WeatherFordLogo from "../../assets/images/logos/site-users/1280px-Weatherford_International_Logo.svg.png";
import PCanaryLogo from "../../assets/images/logos/site-users/Project-Canary-Logo-Blue.webp";
import EosenseLogo from "../../assets/images/logos/site-users/Eosense-logo-w-tagline-300ppi.png";
import GHGSatLogo from "../../assets/images/logos/site-users/GHGSAT_LOGO_1200x630.webp";
import CH4NGEnergyLogo from "../../assets/images/logos/site-users/CH4NGEnergy Logo.jpg";
import ArolyticsLogo from "../../assets/images/logos/site-users/Arolytics+Logo.png";
import GPTLogo from "../../assets/images/logos/site-users/GPT_Logo.png";
// import Logo from '../../assets/images/logos/site-users/'
// import Logo from '../../assets/images/logos/site-users/'

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import completeStatus from "../../assets/images/icons/status-completed.png";
import OngoingStatus from "../../assets/images/icons/status-ongoing.png";
import Button from "../../shared/components/FormElements/Button";
import CANEricSiteUsers from "../components/CANEricSiteUsers";
import ETCSiteUsers from "../components/ETCSiteUsers";
import CANEricSiteUserSearch from "../components/CANEricSiteUserSearch";
import ETCSiteUserSearch from "../components/ETCSiteUserSearch";
import { AuthContext } from "../../shared/components/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
//TODO move to mongodb and move logos to Amazon S3
const Projects = (props) => {
  const auth = useContext(AuthContext);
  const token = auth.token;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadedETCSiteUsers, setLoadedETCSiteUsers] = useState([]);
  const [loadedCANEricSiteUsers, setLoadedCANEricSiteUsers] = useState([]);

  //shows the select company modal when moving back to this page
  useEffect(() => {
    setShowSelectCompanyModal(true);
  }, []);

  useEffect(() => {
    const fetchSiteUserProjects = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/projects/siteuser`,
          "GET",
          null,
          { Authorization: "Bearer " + token }
        );
        console.log(responseData);
        if (responseData) {
          setLoadedETCSiteUsers(
            responseData.filter((item) => item.organization === "ETC")
          );
          setLoadedCANEricSiteUsers(
            responseData.filter((item) => item.organization === "CANEric")
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchSiteUserProjects();
  }, [sendRequest, token]);

  const { showSelectCompanyModal, setShowSelectCompanyModal } = props;

  const [currentCompany, setCurrentCompany] = useState();
  const [currentSiteUserList, setCurrentSiteUserList] = useState([]);
  const [filteredSiteUserList, setFilteredSiteUserList] = useState([]);

  // useEffect(() => {
  //   setFilteredSiteUserList(loadedETCSiteUsers);
  // }, [loadedETCSiteUsers]);

  // useEffect(() => {
  //   setFilteredSiteUserList(loadedCANEricSiteUsers);
  // }, [loadedCANEricSiteUsers]);

  const selectSiteUserListHandler = async (company) => {
    setCurrentCompany(company);
    setShowSelectCompanyModalHandler();
  };

  useEffect(() => {
    if (loadedETCSiteUsers && loadedCANEricSiteUsers) {
      if (currentCompany === "ETC") {
        setFilteredSiteUserList(
          loadedETCSiteUsers.sort((a, b) =>
            a.siteUser.localeCompare(b.siteUser)
          )
        );
      } else if (currentCompany === "CANEric") {
        setFilteredSiteUserList(loadedCANEricSiteUsers);
      }
    }
  }, [currentCompany, loadedETCSiteUsers, loadedCANEricSiteUsers]);

  const setShowSelectCompanyModalHandler = async () => {
    setShowSelectCompanyModal(!showSelectCompanyModal);
  };
  const history = useHistory();
  const navigateToReportHandler = async (rid) => {
    history.push(`reports/${rid}`);
  };

  return (
    <div className="etc-projects__content">
      {currentCompany === "CANEric" && (
        <CANEricSiteUserSearch
          setSiteUserList={setFilteredSiteUserList}
          siteUserList={loadedCANEricSiteUsers}
          currentCompany={currentCompany}
          isLoading={isLoading}
        />
      )}
      {currentCompany === "ETC" && (
        <ETCSiteUserSearch
          setSiteUserList={setFilteredSiteUserList}
          siteUserList={loadedETCSiteUsers}
          currentCompany={currentCompany}
          isLoading={isLoading}
        />
      )}
      {isLoading && <LoadingSpinner />}

      <Modal
        show={showSelectCompanyModal}
        className="select-company-modal"
        header={props.title}
        contentClass="select-company-modal__content"
        footerClass="select-company-modal__footer"
        // width={props.modalWidth}
        // style={{ width: props.modalWidth + "px" }}
        // footer={
        //   <Button type="button" onClick={}>
        //     Close
        //   </Button>
        // }
      >
        <Button
          onClick={() => {
            selectSiteUserListHandler("ETC");
          }}
        >
          ETC
        </Button>
        <Button
          onClick={() => {
            selectSiteUserListHandler("CANEric");
          }}
        >
          CANEric
        </Button>
      </Modal>

      {currentCompany === "ETC" && (
        <ETCSiteUsers
          filteredSiteUserList={filteredSiteUserList}
          completeStatus={completeStatus}
          OngoingStatus={OngoingStatus}
          navigateToReportHandler={navigateToReportHandler}
        />
      )}
      {currentCompany === "CANEric" && (
        <CANEricSiteUsers
          filteredSiteUserList={filteredSiteUserList}
          completeStatus={completeStatus}
          OngoingStatus={OngoingStatus}
          navigateToReportHandler={navigateToReportHandler}
        />
      )}

      {/* Backup */}
      {/*<ul>
        <li>Site User</li>
        <li>description</li>
        <li>ETC Location</li>
        <li></li>Status
      </ul>*/}
      {/*} {siteUserList.map((siteUser) => (
        <ul>
          <li>{siteUser.name}</li>
          <li>{siteUser.description}</li>
          <li>{siteUser.location}</li>
          <li>{siteUser.status}</li>
        </ul>
      ))}*/}
    </div>
  );
};

export default Projects;

import React, { useState } from "react";
import "./Aboutus.css";
import UOFCLOGO from "../../assets/images/logos/uofc-logo-temp-sharpened.png";
import PTACLOGO from "../../assets/images/logos/ptac-logo2.png";
import AILOGO from "../../assets/images/logos/albertainnovates-logo.png";
import NGIFLOGO from "../../assets/images/logos/ngif-logo.jpg";
import NRCANLOGO from "../../assets/images/logos/nrCAN.png";
import Button from "../../shared/components/FormElements/Button";
import Carousel from "react-material-ui-carousel";
import Picture1 from "../../assets/images/etclab/Picture1.png";
import Picture2 from "../../assets/images/etclab/Picture2.jpg";
import Picture3 from "../../assets/images/etclab/Picture3.jpg";
import Picture4 from "../../assets/images/etcfield/Picture4.jpg";
import Picture5 from "../../assets/images/etcfield/Picture5.png";
import Picture6 from "../../assets/images/etcfield/Picture6.jpg";
import Picture7 from "../../assets/images/etcfield/Picture7.jpg";
import Picture8 from "../../assets/images/etcfield/Picture8.jpg";
import Picture9 from "../../assets/images/etcfield/Picture9.jpg";
import Modal from "../../shared/components/UIElements/Modal";

const Aboutus = () => {
  const labPhotos = [Picture1, Picture2, Picture3];
  const fieldPhotos = [
    Picture4,
    Picture5,
    Picture6,
    Picture7,
    Picture8,
    Picture9,
  ];

  const tempDesc =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud" +
    "exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum";

  const uofcContact = "Ian Gates\nidgates@ucalgary.ca";
  const uofcLink =
    "https://research.ucalgary.ca/energy/energy-research/global-research-initiative";
  const uofcDesc =
    "The University of Calgary is a global intellectual hub, and one of the Canada’s top five research universities. As an institution that is truly integrated with the community, the University of Calgary is well-positioned to provide emissions management solutions to the energy industry as we continue to transition to cleaner ways of producing natural resources and powering our world.  The University of Calgary’s Schulich School of Engineering has teams of dedicated researchers and world- class testing equipment that can be brought to bear to help to study emissions management and development of clean energy systems. As part of the CERIN Initiative, the University of Calgary has provided support in the ETC Laboratory and through integration of teams of highly qualified personnel (HQP) from various research groups, who are working directly on CERIN projects to support activities around evaluation of methane mitigation solutions and modeling of methane emissions.  The University of Calgary and the Global Research Initiative (GRI) are working with CERIN to create a network of global hubs for discovery, creativity and innovation in unconventional energy research. ";

  const ptacContact =
    "Brian Spiegelmann\nbspiegelmann@ptac.org\n(403) 669-4414";
  const ptacLink = "https://caneric.ptac.org/";
  const ptacDesc =
    "PTAC's CanERIC program is a pan-Canadian network of researchers and end-users collaborating to develop and deploy technologies to reduce methane emissions. This network is providing industry with a platform to articulate and rank their most pressing methane emission challenges. Clean Tech technology vendors are able to better understand the potential market for their products, and develop appropriate solutions. The industry works collaboratively with the technology vendors to lab and field test their technologies and the vendors are able to refine their designs with the testing information. Institutional and academic members are also involved in CanERIC to provide the expertise to conduct the lab tests and monitor and analyze field tests for the network. ";

  const aiContact =
    "Claude Ghazar\nRecovery Technologies Director\nClaude.Ghazar@albertainnovates.ca\nOffice: 403-210-5284\nMobile: 403-669-0636";
  const aiLink =
    "https://albertainnovates.ca/app/uploads/2020/08/CERIN-Project-Summary-2020-07-09.pdf";
  const aiDesc =
    "Alberta Innovates is the province’s largest research and innovation agency. From funding to commercialization, we are Alberta’s innovation engine. We are working to solve today’s challenges, create new opportunities and forge a healthy, sustainable and prosperous future for Albertans today and for generations to come. We are where innovation happens, and we touch every corner of the province for the benefit of all Albertans.";

  const nrContact = "energyinnovation-innovationenergetique@nrcan-rncan.gc.ca";
  const nrLink =
    "https://www.nrcan.gc.ca/science-data/funding-partnerships/funding-opportunities/funding-grants-incentives/energy-innovation-program/canadian-emissions-reduction-innovation-network/21778";
  const nrDesc =
    "Natural Resources Canada’s (NRCan) Office of Energy Research and Development (OERD) leads the Government of Canada’s efforts in delivering energy research, development, and demonstration (RD&D) funding, accelerating efforts in energy innovation and cleantech programming. With a focus on influencing the pace and direction of energy system transformation, OERD targets the most impactful technologies to maximize environmental and economic outcomes.  Leveraging over forty years of experience and unique science and technology expertise, OERD invests in thirteen federal departments and agencies to undertake RD&D, as well as a wide range of Canadian small and medium enterprises, utilities, industry, and other firms, all in support of Canada’s energy innovation and climate change goals.\n\n " +
    "As part of CERIN, NRCan is investing $9.15M through our Energy Innovation Program, to build technology-testing capacity through this collaborative program, targeting infrastructure investments at existing facilities or sites within Canada and the creation of a consortia capable of connecting national expertise and facilities to an integrated network.";

  const ngifContact = "Jonathan Bryan\njbryan@ngif.ca\n(403) 815-3703";
  const ngifLink = "https://www.ngif.ca/";
  const ngifDesc1 =
    "The NGIF Emissions Testing Centre (ETC) is a world class, first of kind testing platform, led by both Industry and Government, which allows multiple startups to validate their emissions solutions in a live gas operations environment.  This is an exciting opportunity for cleantech companies involved in methane emissions measurement or control to come and test their precommercial technology solutions in a world class testing centre, which is built into Tourmaline Oil’s live operating environment. Technologies will be tested under real-world conditions, with baseline results quantified and provided to site users as a means for quantitatively validating their technology. The live host site for the platform includes the West Wolf Lake Gas Plant, a 60 MMscf/day Gas processing facility located 30km South-West of Edson, Alberta. The facility is jointly owned by Perpetual Energy and Tourmaline Oil Corp, and operated by Tourmaline. Clean technology testing scopes include site emissions monitoring, flare monitoring and mitigation, compressor monitoring/emissions mitigation, tank (water, condensate, NGL) monitoring/mitigation, and all gas processing technology related to emissions monitoring or mitigation of methane release. The ETC secondary field test site is a controlled release vent site as part of a Tourmaline suspended wellsite, located 75 km north of Grande Prairie, Alberta.";
  const ngifDesc2 =
    "This site allows for surface casing vent gas to be released at different points and heights, and with variable rates, all for validation of methane detection equipment. Tourmaline Oil has also contributed over 100 different wellsites across Alberta and British Columbia for use in clean technology testing of emissions control and mitigation. Finally, the ETC includes drilling and frac rigs that Tourmaline has made available, for technology testing of D&C emissions mitigation technology. The rigs are equipped with diesel and natural gas flow measurement, and stack emissions are measured as required for technology testing. " +
    "The ETC also provides controlled environment testing, either for lower TRL technologies or as a precursor to field testing, in our ETC lab, which is located at the University of Calgary. The ETC lab comes fully equipped with analytical instrumentation, facilities for controlled methane releases or testing of wellsite pneumatic vent replacement technology, and a team of technicians and highly qualified personnel to assist in technology testing. The ETC lab utilizes the world-class capabilities of the University of Calgary to help move cleantech methane companies towards field deployment. The lab is available for booking, and can provide important collaboration opportunities with other academic or research institutions.";

  return (
    <React.Fragment>
      <div className="background" />
      <div className="about-us__content">
        <p className="about-us__title">About Us</p>
        {/* <form onSubmit={`${process.env.REACT_APP_BACKEND_URL}/upload`}>
          <input type="file" />
          <button type="submit">Butt</button>
        </form> */}
        <div className="institutions">
          <Institution
            image={AILOGO}
            class="AI"
            title="Alberta Innovates"
            desc={aiDesc}
            contact={aiContact}
            link={aiLink}
            linkLeft
          />
          <Institution
            image={NRCANLOGO}
            class="NRCan"
            title="Natural Resources Canada"
            desc={nrDesc}
            contactLink={nrContact}
            link={nrLink}
            linkLeft
          />
          <Institution
            image={PTACLOGO}
            class="PTAC"
            title="Petroleum Technology Alliance Canada"
            desc={ptacDesc}
            contact={ptacContact}
            link={ptacLink}
          />

          <Institution
            title="University of Calgary"
            class="UofC"
            image={UOFCLOGO}
            desc={uofcDesc}
            contact={uofcContact}
            link={uofcLink}
          />

          <Institution
            image={NGIFLOGO}
            class="NGIF"
            desc={ngifDesc1}
            desc2={ngifDesc2}
            contact={ngifContact}
            title="Natural Gas Innovation Fund"
            link={ngifLink}
            ngif
            modalWidth={1000}
          />
        </div>
        {/* <div className="ngif-box ">
          <div className="ngif__logo-box">
            <img className="ngif__logo" src={NGIFLOGO} alt="x logo" />
          </div>
          <div className="ngif__description-box">
            <div className="ngif__description">
              <div className="ngif__desc1">{ngifDesc1}</div>
              <div className="ngif__desc2">{ngifDesc2}</div>
            </div>
            <p className="ngif__contact">{ngifContact}</p>
          </div>

          <div className="ngif__learn-more-box">
            <Button href={ngifLink}>Learn More</Button>
          </div>
        </div> */}

        {/* <div className="carousels">
          <div className="lab-box">
            <h2>ETC LAB</h2>

            <Carousel>
              {labPhotos.map((item) => (
                <img className="carousel-image" src={item} alt="lab-img" />
              ))}
            </Carousel>
          </div>
          <div className="field-box">
            <h2>ETC FIELD</h2>
            <Carousel>
              {fieldPhotos.map((item) => (
                <div className="field-carousel">
                  <img className="carousel-image" src={item} alt="field-img" />
                </div>
              ))}
            </Carousel>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

const Institution = (props) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={`institution__box ${props.class}__box`}>
      <div className={`institution__logo-box ${props.class}__logo-box`}>
        <img
          className={`institution__logo ${props.class}__logo`}
          src={props.image}
          alt={`${props.class} logo`}
        />
      </div>
      <div className="institution__text">
        <div
          className={`institution__content ${props.class}__content ${
            showMore ? "institution__content__show-more" : ""
          }`}
        >
          <div
            className={`institution__description-box ${
              props.ngif ? "ngif-desc" : ""
            }`}
          >
            <div className="institution__description desc1">
              <p>{props.desc}</p>
            </div>
            {props.desc2 && (
              <div className="institution__description desc2">
                <p>{props.desc2}</p>
              </div>
            )}
          </div>
          {(props.contact || props.contactLink) && (
            <div
              className={`institution__contact ${
                props.linkLeft ? "contact__align-left" : ""
              }`}
            >
              <p
                className={`institution__contactInfo ${
                  props.linkLeft ? "contactInfo__align-left" : ""
                }`}
              >
                <h3>Contact Information:</h3>
                {props.contact}
                {props.contactLink && (
                  <a href={props.contactLink}>{props.contactLink}</a>
                )}
              </p>
              <br />
            </div>
          )}
        </div>
        <div className={`institution__buttons ${props.class}__buttons`}>
          <p
            className="about-us__show-more-less__button"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? "- Show Less" : "+ Show More"}
          </p>
          <Button className="about-us__learn-more__button" href={props.link}>
            Learn More
          </Button>
        </div>
      </div>
    </div>
  );
};

const Steakholder = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openCloseInstitutionModalHandler = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div className={`institution__box ${props.ngif ? "ngif" : ""}`}>
      <div className={`${props.ngif ? "ngif-left" : ""}`}>
        {isOpen && (
          <Modal
            className="institution-modal"
            show={isOpen}
            header={props.title}
            contentClass="institution-modal__content"
            footerClass="institution-modal__footer"
            width={props.modalWidth}
            style={{ width: props.modalWidth + "px" }}
            footer={
              <Button type="button" onClick={openCloseInstitutionModalHandler}>
                Close
              </Button>
            }
          >
            <div
              className={`institution__description-box ${
                props.ngif ? "ngif-desc" : ""
              }`}
            >
              <div className="institution__description desc1">
                <p>{props.desc}</p>
              </div>
              {props.desc2 && (
                <div className="institution__description desc2">
                  <p>{props.desc2}</p>
                </div>
              )}
            </div>
            <div
              className={`institution__contact ${
                props.linkLeft ? "contact__align-left" : ""
              }`}
            >
              <p
                className={`institution__contactInfo ${
                  props.linkLeft ? "contactInfo__align-left" : ""
                }`}
              >
                <h3>Contact Information:</h3>
                {props.contact}
                {props.contactLink}
              </p>
              <br />
              {props.link && (
                <p
                  className={`institution__link ${
                    props.linkLeft ? "link__align-left" : ""
                  }`}
                >
                  For more information, visit:
                  <br />
                  <a href={props.link}>
                    {props.link.length > 35
                      ? props.link.substring(0, 33) + ".."
                      : props.link}
                  </a>
                </p>
              )}
            </div>
          </Modal>
        )}
        <div className="institution__logo-box">
          <img className="institution__logo" src={props.image} alt="x logo" />
        </div>
        <div className="institution__learn-more-box">
          <Button onClick={openCloseInstitutionModalHandler}>Learn More</Button>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;

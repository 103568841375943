import React from "react";

import { useReducer, useEffect } from "react";
import { validate } from "../../util/validators";
import "./Input.css";
import { TextareaAutosize } from "@mui/core";
const inputReducer = (state, action) => {
  // this is a reducer function
  switch (action.type) {
    case "CHANGE":
      return {
        ...state, // ... copies all name-value pairs from the old object into the new object
        value: action.val, //select  keys/properties can be changed
        isValid: validate(action.val, action.validators), //validation logic
        // as a result, the new state object will contain the old object's values in addition to modified or added values
        // in this case, the value and isValid parameters are added/changed
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      //returns if nothing matches
      return state;
  }
};
const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.defaultValue || "",
    isValid: props.defaultValid || false,
    isTouched: false,
  }); /*useReducer can take an optonal second argument that sets the initial state
    useReducer always returns current state and a dispatch function.
   a reducer is a function that receives an action that can be dispatched 
    and then updates the current state based on the action received.
    Finally it returns the new state and is given back to the component to be re-rendered
    */

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, onInput, value, isValid]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };
  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };
  let element;
  let className;
  if (props.rounded) {
    className = props.className + " rounded";
  } else {
    className = props.className;
  }
  switch (props.element) {
    case "input":
      element = (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          value={inputState.value ? inputState.value : props.defaultValue}
          onBlur={touchHandler}
          className={props.className}
          maxLength={props.maxLength}
          ref={props.ref}
          disabled={props.disabled}
          style={props.style}
          onKeyDown={props.onKeyDown}
          accept = {props.accept}
        />
      );
      break;
    case "select":
      element = (
        <select
          className={props.className}
          id={props.id}
          onChange={changeHandler}
          defaultValue={props.defaultValue}
        >
          {props.children}
        </select>
      );
      break;
    case "textareaauto":
      element = (
        <TextareaAutosize
          id={props.id}
          style={props.style}
          maxRows={props.maxRows}
          minRows={props.minRows}
          onBlur={touchHandler}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          onChange={changeHandler}
          className={className}
          defaultValue={props.defaultValue}
        />
      );
      break;

    default:
      element = (
        <textarea
          id={props.id}
          rows={props.rows || 3}
          onChange={changeHandler}
          value={inputState.value ? inputState.value : props.defaultValue}
          onBlur={touchHandler}
          className={className}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
        />
      );
  }

  return (
    <div
      className={`form-control ${
        !inputState.isValid &&
        inputState.isTouched &&
        "form-control--invalid" &&
        !!props.noValid &&
        "no-focus" &&
        props.noFocus
      }`}
    >
      {/*if inputState is not valid, add a className for invalid*/}

      {/*defaults to 3 rows if props.rows is undefined*/}
      {element}

      {!inputState.isValid && inputState.isTouched && (
        <p className="errorText" style={props.errorStyle}>
          {props.errorText}
        </p>
      )}
    </div>
  );
};

export default Input;

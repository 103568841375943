import React from "react";

import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
  return (
    <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
      {props.loadingProgress ? (
        <React.Fragment>
          {props.message && (
            <div className="loading-message">
              <h3>{props.message}</h3>
            </div>
          )}
          <progress value={props.loadingProgress} max={100} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="lds-dual-ring"></div>

          {props.message && (
            <div className="loading-message">
              <h3>{props.message}</h3>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default LoadingSpinner;

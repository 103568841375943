import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./MainHeader.css";

const MainHeader = (props) => {
  const [headerClass, setHeaderClass] = useState("header__top-of-page");
  //checks if the window is scrolled to the top of the page and adjusts the header class accordingly
  useEffect(() => {
    window.onscroll = (ev) => {
      if (window.scrollY > 0) {
        setHeaderClass("header__scrolled-down");
      } else {
        setHeaderClass("header__top-of-page");
      }
    };
  }, []);

  const location = useLocation();

  return (
    <header
      className={`main-header ${headerClass} ${
        location.pathname === "/" && "landing-header"
      }`}
    >
      {props.children}
    </header>
  ); //props.children refers to all the items between the opening and closing tags of your component
};

export default MainHeader;

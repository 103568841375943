import React, { useRef, useState, useEffect } from "react";

import "./ImageUpload.css";
import Button from "./Button";

const ImageUpload = (props) => {
  const filePickerRef = useRef();
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  let setPreview = props.setPreview || setPreviewUrl;
  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    }; //executes after file is read
    fileReader.readAsDataURL(file);
    setPreview(previewUrl);
  }, [file, previewUrl, setPreview]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }

    props.onInput(props.id, pickedFile, fileIsValid);
  };
  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className={props.className}>
      <input
        className="thumbnail"
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".jpg,.png,.jpeg,.svg"
        onChange={pickedHandler}
      />
      <div className={`image-upload-box ${props.center && "center"}`}>
        <div className="image-upload__preview">
          <img
            src={previewUrl ? previewUrl : props.defaultValue}
            alt="Preview"
          />

          <Button
            className="image-upload__button"
            type="button"
            onClick={pickImageHandler}
          >
            PICK IMAGE
          </Button>
        </div>
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;

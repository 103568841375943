import React, { useContext, useEffect, useState } from "react";
import "./ViewProject.css";
import "./AddProject.css";
import { useHistory, useParams } from "react-router";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/components/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import AttachmentsBox from "../components/Project/AttachmentsBox";
import PDFViewer from "../../shared/components/UIElements/PDFViewer";
import ViewMemberList from "../components/Members/ViewMemberList";
import ContactList from "../components/Project/ContactList";
import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import { getUserMemberRole } from "../../shared/util/MemberRoleChecker";
import PDFUpload from "../../shared/components/FormElements/PDFUpload";
import AttachmentsUpload from "../../shared/components/FormElements/AttachmentsUpload";
import ProjectPreviewForm from "../components/Project/ProjectPreviewForm";
import ProjectListItem from "../components/Project/ProjectListItem";
import ContactListForm from "../components/Project/ContactListForm";
import KeywordListForm from "../components/Project/KeywordListForm";
import Modal from "../../shared/components/UIElements/Modal";
import { isMobile } from "react-device-detect";
import axios from "axios";

const ProjectForm = (props) => {
  const { loadedProject, userMemberRole } = props;
  return (
    <React.Fragment>
      <div className="viewpage-wrap">
        {/**
         * PROJECT OVERVIEW
         */}
        <div
          className="addprojectpage addproject-page1"
          style={{
            display: props.currentSection === "Project" ? "" : "none",
          }}
        >
          <div className="project-box">
            <h2>Overview</h2>

            <Input
              element="textareaauto"
              id="overview"
              minRows={30}
              placeholder="Enter Overview Here"
              onInput={props.inputHandler}
              validators={[]}
              className="project-overview"
              defaultValue={props.loadedProject.contents.overview}
            />
            <div />
          </div>

          <div className="project-box">
            <ContactListForm
              inputHandler={props.inputHandler}
              formState={props.formState}
            />

            {/**
             * KEYWORD LIST HERE
             */}
          </div>
          <div className="project-box">
            <KeywordListForm
              inputHandler={props.inputHandler}
              formState={props.formState}
            />
          </div>
        </div>
        {/**
         * PDF HERE!
         */}
        <div
          className="addprojectpage addproject-page2"
          style={{
            display: props.currentSection === "PDF" ? "" : "none",
          }}
        >
          <div className="project-box">
            <h2>PDF</h2>
            <PDFUpload id="pdf" onInput={props.inputHandler} />
          </div>
        </div>
        {/**
         * PROJECT ATTACHMENTS
         */}

        <div
          className="addprojectpage addproject-page3"
          style={{
            display: props.currentSection === "Attachments" ? "" : "none",
          }}
        >
          <div className="project-box">
            <AttachmentsUpload id="attachments" onInput={props.inputHandler} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const MemoForm = React.memo(ProjectForm);

const ViewProject = (props) => {
  const pid = useParams().pid;
  const { isLoading, error, sendRequest, clearError, setError } =
    useHttpClient();
  const [loadedProject, setLoadedProject] = useState();
  const auth = useContext(AuthContext);
  const token = auth.token;
  const [currentPageName, setCurrentPageName] = useState("Project");
  const [projectType, setProjectType] = useState("Public");
  const [userList, setUserList] = useState([]);
  const [previewDefaults, setPreviewDefaults] = useState();
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);

  const [userMemberRole, setUserMemberRole] = useState("Member");

  const [thumbnailSRC, setThumbnailSRC] = useState();
  const fetchImageLink = async (fid, pid) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}/files/${fid}`,
      "GET",
      null,
      { Authorization: "Bearer " + token }
    );
  };

  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      overview: {
        value: "",
        isValid: false,
      },
      pdf: {
        value: null,
        isValid: false,
      },
      attachments: {
        value: [],
        isValid: false,
      },
      existingAttachments: {
        value: [],
        isValid: false,
      },
      projectName: {
        value: "",
        isValid: false,
      },
      // projectDesc: {
      //   value: "",
      //   isValid: false,
      // },
      thumbnail: {
        value: null,
        isValid: false,
      },
      projectType: {
        value: "Public",
        isValid: true,
      },
      downloadEmail: {
        value: "",
        isValid: false,
      },
      keywords: {
        value: [],
        isValid: false,
      },

      contacts: {
        value: [],
        isValid: false,
      },

      //members: {
      // value:null,
      // isValid:false
      //   }
    },
    false
  );

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let url;
        if (props.type === "Published") {
          url = `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}`;
        } else if (props.type === "Review") {
          url = `${process.env.REACT_APP_BACKEND_URL}/projects/review/${pid}`;
        } else if (props.type === "Edit") {
          url = `${process.env.REACT_APP_BACKEND_URL}/projects/edit/${pid}`;
        }
        const responseData = await sendRequest(url, "GET", null, {
          Authorization: "Bearer " + token,
        });
        //let memberListToConvert;
        if (responseData) {
          setLoadedProject(responseData.project);
          setProjectType(responseData.project.type);
          setPreviewDefaults({
            projectName: responseData.project.projectName,
            description: responseData.project.contents.description,
            thumbnail: responseData.project.contents.thumbnail,
          });
          setUserList(responseData.project.members); // users from responseData here, not the one from above
          if (auth.userId) {
            setUserMemberRole(
              getUserMemberRole(responseData.project.members, auth.userId)
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (props.type) {
      fetchProject();
    }
  }, [sendRequest, props.type, token, auth.userId, auth.userRole, pid]);

  const memberTabHandler = () => {
    setCurrentPageName("Members");
  };
  const projectTabHandler = () => {
    setCurrentPageName("Project");
  };

  const isEditingHandler = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const [showSubmitEditConfirm, setShowSubmitEditConfirm] = useState(false);

  const openSubmitEditConfirmHandler = () => {
    setShowSubmitEditConfirm(true);
  };
  const closeSubmitEditConfirmHandler = () => {
    setShowSubmitEditConfirm(false);
  };

  const [loadingProgress, setLoadingProgress] = useState();

  const submitEditHandler = async () => {
    closeSubmitEditConfirmHandler();
    setLoadingMessage("Please wait...");
    try {
      const formData = new FormData();

      const inputs = formState.inputs;
      if (inputs.overview.value) {
        formData.append("overview", inputs.overview.value);
      }

      if (inputs.contacts.value.length > 0) {
        //   for (const x in contactList) {
        //     console.log(contactList[x]);
        formData.append("contacts[]", JSON.stringify(inputs.contacts.value));
        //     }
      }

      if (inputs.keywords.value.length > 0) {
        // for (const x in keywordList) {
        formData.append("keywords[]", JSON.stringify(inputs.keywords.value));
        //     }
      }
      formData.append(
        "projectName",
        inputs.projectName.value
          ? inputs.projectName.value
          : loadedProject.projectName
      );
      formData.append("projectType", loadedProject.type); // needed for multer

      // if (inputs.projectDesc.value) {
      //   formData.append("projectDesc", inputs.projectDesc.value);
      // }

      if (inputs.pdf.value) {
        formData.append(
          "pdf",
          formState.inputs.pdf.value
            ? formState.inputs.pdf.value
            : loadedProject.contents.pdf
        );
      }
      if (inputs.thumbnail.value) {
        formData.append(
          "thumbnail",
          formState.inputs.thumbnail.value
            ? formState.inputs.thumbnail.value
            : loadedProject.contents.thumbnail
        );
      }
      if (inputs.attachments.value) {
        for (const f in inputs.attachments.value) {
          formData.append("attachments", inputs.attachments.value[f]);
        }
      }
      if (inputs.existingAttachments.value.length > 0) {
        // for (const x in keywordList) {
        formData.append(
          "existingAttachments[]",
          JSON.stringify(inputs.existingAttachments.value)
        );
        //     }
      }

      //TODO
      //Fix errors not showing up properly from filestream requests
      setLoadingMessage("Uploading Files...");
      setLoadingProgress(0);
      console.log(formData);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/projects/${loadedProject.id}/edit/content/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "token " + auth.token,
            },
            onUploadProgress: (p) => {
              const percentCompleted = Math.round((p.loaded * 100) / p.total);
              setLoadingProgress(percentCompleted);
            },
          }
        )
        .then((res) => {
          setLoadingProgress();
          setLoadingMessage();
          setShowSubmitEditConfirm(false);

          history.push("/myprojects");
        });
      // let responseData = await sendRequest(
      //   `${process.env.REACT_APP_BACKEND_URL}/projects/${loadedProject.id}/edit/content/`,
      //   "POST",
      //   formData,
      //   { Authorization: "Bearer " + token },
      //   true // not json
      // );
    } catch (err) {
      console.log(err);
    }

    setIsEditing(false);
  };
  const [previewUrl, setPreviewUrl] = useState();

  const previewChangeHandler = (previewUrl) => {
    setPreviewUrl(previewUrl);
  };

  const [downloadEmail, setDownloadEmail] = useState();
  const [showEmailFormModal, setShowEmailFormModal] = useState(false);

  const changeShowEmailFormHandler = () => {
    if (showEmailFormModal) {
      setShowEmailFormModal(false);
    } else {
      setShowEmailFormModal(true);
    }
  };

  const submitDownloadEmailHandler = () => {
    if (formState.inputs.downloadEmail.isValid) {
      setDownloadEmail(formState.inputs.downloadEmail.value);
    }
    changeShowEmailFormHandler();
  };

  const [loadingMessage, setLoadingMessage] = useState();

  const [currentPage, setCurrentPage] = useState("Project");
  const [currentSection, setCurrentSection] = useState("PDF");

  const changePageHandler = (section) => {
    switch (section) {
      case "Details":
        setCurrentPage("Details");
        setCurrentSection("Details");
        break;
      case "PDF":
        setCurrentPage("PDF");
        setCurrentSection("PDF");

        break;
      case "Attachments":
        setCurrentPage("Attachments");
        setCurrentSection("Attachments");

        break;
      case "ProjectView":
        setCurrentPage("ProjectView");
        setCurrentSection("ProjectView");

        break;
      case "Members":
        setCurrentPage("Members");
        setCurrentSection("Members");

        break;
      case "Institutions":
        setCurrentPage("Institutions");
        setCurrentSection("Institutions");

        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <div className="view-project__background" />
      <ErrorModal error={error} onClear={clearError} pushTo="/reports" />
      <Modal
        show={showEmailFormModal}
        header="Please enter your email to proceed with download"
        contentClass="email-form-modal__content"
        footerClass="email-form-modal__footer"
        footer={
          <React.Fragment>
            <Button
              disabled={!formState.inputs.downloadEmail.isValid}
              onClick={submitDownloadEmailHandler}
            >
              Submit
            </Button>
            <Button onClick={changeShowEmailFormHandler}>Close</Button>
          </React.Fragment>
        }
      >
        <React.Fragment>
          <Input
            id="downloadEmail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address"
            element="input"
            onInput={inputHandler}
          />
        </React.Fragment>
      </Modal>
      <Modal
        show={showSubmitEditConfirm}
        header="Submit your edit?"
        contentClass="submit-edit-confirm-modal__content"
        footerClass="submit-edit-confirm-modal__footer"
      >
        <Button
          type="button"
          onClick={submitEditHandler}
          className="modal-button"
        >
          Confirm
        </Button>
        <Button
          onClick={closeSubmitEditConfirmHandler}
          className="modal-button"
        >
          Cancel
        </Button>
      </Modal>
      {(isLoading || loadingProgress || loadingMessage) && (
        <LoadingSpinner
          asOverlay
          message={loadingMessage}
          loadingProgress={loadingProgress}
        />
      )}
      {!isLoading && loadedProject && (
        <div className="view-project-content">
          {/** tabs at the top of the page */}
          {auth.isLoggedIn && props.type !== "Edit" && (
            <div className="view-tabs-wrap">
              <div
                className="view-project-tabs"
                //style={projectType === "Public" ? { display: "none" } : {}}
              >
                <div
                  className={`view-tab project-page-tab ${
                    currentPageName === "Members" ? "" : "tab-activated"
                  }`}
                  onClick={projectTabHandler}
                >
                  Project
                </div>
                <div
                  className={`view-tab members-page-tab ${
                    currentPageName === "Project" ? "" : "tab-activated"
                  }`}
                  onClick={memberTabHandler}
                >
                  Members
                </div>
                <hr className="view-tab-line" />
              </div>
              {currentPageName === "Project" && (
                <div className="vieweditproject-tabs-wrap">
                  <div className="vieweditproject-tabs">
                  {loadedProject.contents.overview && loadedProject.contacts.length && (

                    <div
                      className={`tab-button tab-details ${
                        currentSection === "Project" ? "selected" : ""
                      }`}
                      onClick={() => {
                        changePageHandler("Project");
                        setCurrentSection("Project");
                      }}
                    >
                      Details
                    </div>
                  )}
                    <div
                      className={`tab-button tab-PDF ${
                        currentSection === "PDF" ? "selected" : ""
                      }`}
                      onClick={() => {
                        changePageHandler("PDF");
                        setCurrentSection("PDF");
                      }}
                    >
                      PDF
                    </div>
                    <div
                      className={`tab-button tab-attachments ${
                        currentSection === "Attachments" ? "selected" : ""
                      }`}
                      onClick={() => {
                        changePageHandler("Attachments");
                        setCurrentSection("Attachments");
                      }}
                    >
                      Attachments
                    </div>
                    {isEditing && (
                      <div
                        className={`tab-button tab-project-view ${
                          currentSection === "ProjectView" ? "selected" : ""
                        }`}
                        onClick={() => {
                          changePageHandler("ProjectView");
                          setCurrentSection("ProjectView");
                        }}
                      >
                        Project View
                      </div>
                    )}
                    {/* <div
                  className={`tab-button tab-members ${
                    currentSection === "Members" ? "selected" : ""
                  }`}
                  onClick={() => {
                    changePageHandler("Members");
                    setCurrentSection("Members");
                  }}
                >
                  Members
                </div> */}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* //project page here */}
          {currentPageName === "Project" ? (
            <div className="view-project__project">
              <div className="view-project__header">
                {(userMemberRole === "Contributor" ||
                  userMemberRole === "Author") &&
                  (!isEditing ? (
                    <div className="edit-button">
                      {!isMobile && (
                        <Button type="button" onClick={isEditingHandler}>
                          Edit
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="submit-cancel-buttons">
                      <Button
                        type="button"
                        onClick={openSubmitEditConfirmHandler}
                      >
                        Submit
                      </Button>
                      <Button type="button" onClick={isEditingHandler}>
                        Cancel
                      </Button>
                    </div>
                  ))}
                <p className="view-project__author">
                  Author: {loadedProject.author.name}
                </p>
              </div>

              {/**
               * PROJECT TITLE
               */}
              <h1
                className="view-project__title"
                onClick={() => {
                  console.log(formState);
                }}
              >
                {loadedProject.contents.title}
              </h1>

              {isEditing ? (
                // IF EDITING
                <React.Fragment>
                  <div className="project-edit-form">
                    <MemoForm
                      inputHandler={inputHandler}
                      loadedProject={loadedProject}
                      currentSection={currentSection}
                      userMemberRole={userMemberRole}
                      formState={formState}
                    />
                    {/**
                     * PROJECT PREVIEW CARD
                     */}
                    <div
                      className="addprojectpage addproject-page4"
                      style={{
                        display: currentSection === "ProjectView" ? "" : "none",
                      }}
                    >
                      {" "}
                      <ProjectPreviewForm
                        edit
                        inputHandler={inputHandler}
                        previewChangeHandler={previewChangeHandler}
                        defaultValues={previewDefaults}
                      />
                      <div className="project-box">
                        <div className="project-preview">
                          {!isLoading && (
                            <ProjectListItem
                              title={
                                formState.inputs.projectName.value
                                  ? formState.inputs.projectName.value
                                  : previewDefaults.projectName
                              }
                              thumbnail={
                                previewUrl
                                  ? previewUrl
                                  : `${process.env.REACT_APP_ASSET_URL}/${previewDefaults.thumbnail}`
                              }
                              previewOnly
                              // description={
                              //   formState.inputs.projectDesc.value
                              //     ? formState.inputs.projectDesc.value
                              //     : previewDefaults.description
                              // }
                            ></ProjectListItem>
                          )}
                        </div>
                      </div>
                    </div>

                    {/**
                     * CONTACT LIST
                     */}

                    {/* <ContactListForm
                      inputHandler={inputHandler}
                      formState={formState}
                    /> */}

                    {/**
                     * KEYWORD LIST HERE
                     */}
                    {/* <div>
                      <KeywordListForm
                        inputHandler={inputHandler}
                        formState={formState}
                      />
                    </div> */}
                  </div>
                </React.Fragment>
              ) : (
                //IF NOT EDITING

                <React.Fragment>
                  {/**
                   * PROJECT OVERVIEW
                   */}


                  <div
                    className="addprojectpage addproject-page1"
                    style={{
                      display: currentSection === "Project" ? "" : "none",
                    }}
                  >
                    {/* {loadedProject.contents.overview > 0 && ( */}
                    {loadedProject.contents.overview && (
                      <div className="project-box projectview-overview">
                        <div className="view-project__overview">
                          <h2>Overview</h2>
                          {loadedProject.contents.overview}
                        </div>
                      </div>
                    )}
                    {/* )
                    } */}
                    {loadedProject.contacts.length > 0 && (
                      <div className="project-box">
                        <div className="contact-list">
                          <ContactList contactList={loadedProject.contacts} />
                        </div>
                      </div>
                    )}
                  </div>
                  {/**
                   * PDF HERE!
                   */}
                  <div
                    className="addprojectpage addproject-page2"
                    style={{ display: currentSection === "PDF" ? "" : "none" }}
                  >
                    <div className="project-box view-project__pdf">
                      <PDFViewer
                        projView
                        pdf={loadedProject.contents.pdf}
                        uid={auth.userId}
                        pid={loadedProject.id}
                        downloadEmail={downloadEmail}
                        changeShowEmailFormHandler={changeShowEmailFormHandler}
                      />
                    </div>
                  </div>
                  {/**
                   * PROJECT ATTACHMENTS
                   */}

                  <div
                    className="addprojectpage addproject-page3"
                    style={{
                      display: currentSection === "Attachments" ? "" : "none",
                    }}
                  >
                    {loadedProject.contents.attachments &&
                      loadedProject.contents.attachments.length > 0 && (
                        <div className="project-box projectviews-attachments">
                          <h1 className="attachments-box-label">
                            {" "}
                            Attachments{" "}
                          </h1>
                          <AttachmentsBox
                            type="view"
                            newItems={loadedProject.contents.attachments}
                            pid={loadedProject.id}
                            userMemberRole={userMemberRole}
                            setLoadedProject={setLoadedProject}
                            projectViewType={props.type}
                            projectType={loadedProject.type}
                            projectName={loadedProject.projectName}
                            downloadEmail={downloadEmail}
                            changeShowEmailFormHandler={
                              changeShowEmailFormHandler
                            }
                          />
                        </div>
                      )}
                  </div>

                  {/**
                   * CONTACT LIST
                   */}

                  {/* <div
                    className="addprojectpage addproject-page4"
                    style={{
                      display: currentSection === "ProjectView" ? "" : "none",
                    }}
                  >
                    <div className="project-box">
                    {loadedProject.contacts.length > 0 && (
                      <div className="contact-list">
                        <ContactList contactList={loadedProject.contacts} />
                      </div>
                    )}
                    </div>
                  </div> */}
                </React.Fragment>
              )}
            </div>
          ) : (
            // <div
            //   className="addprojectpage addproject-page5"
            //   style={{ display: currentSection === "Members" ? "" : "none" }}
            // >
            <div className="view-project-member-wrap">
              <div className="project-box view-members">
                <div className="view-project__members">
                  <ViewMemberList
                    userMemberRole={userMemberRole}
                    userList={userList}
                    setUserList={setUserList}
                    projectId={loadedProject.id}
                    projectType={loadedProject.type}
                  />
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      )}
      <ErrorModal error={error} onClear={clearError} />
    </React.Fragment>
  );
};

export default ViewProject;

import React, { useState } from "react";
import { useHistory } from "react-router";
import Button from "../../shared/components/FormElements/Button";
import Modal from "../../shared/components/UIElements/Modal";
import "./SiteUser.css";
const SiteUser = (props) => {
  const { siteUser } = props;

  const history = useHistory();
  const [showReportList, setShowReportList] = useState(false);

  const moveToReportsHandler = async () => {
    if (siteUser.reports.length < 2) {
      history.push(`/projects/${siteUser.reports[0].id}`);
    } else {
      setShowReportList(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        error
        show={showReportList}
        contentClass="report-list-modal__content"
        footer={
          <Button
            onClick={() => {
              console.log(showReportList);
              setShowReportList(false);
            }}
          >
            Exit
          </Button>
        }
      >
          <h2>Reports</h2>
        {siteUser.reports.map((report) => (
          <div className="site-user__report">
            <a href={`/projects/${report.id}`}>{report.name}</a>
          </div>
        ))}
      </Modal>
      <div className="site-user" onClick={moveToReportsHandler}>
        {siteUser.logo ? (
          <img className="site-user__logo" src={siteUser.logo} alt="logo" />
        ) : (
          <p
            className={`${
              siteUser.name.length < 10
                ? "site-user__name"
                : "site-user__name-long"
            }`}
          >
            {siteUser.name}
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default SiteUser;

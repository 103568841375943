import React, { useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
//A special type of link that can analyze the url or customize the link's appearance
import { AuthContext } from "../context/auth-context";
import NavDropdown from "./NavDropdown";
import ScrollUp from "./ScrollUp";
import { isMobile } from "react-device-detect";

import "./NavLinks.css";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const closeDrawer = props.closeDrawerHandler;
  const history = useHistory();
  const logoutHandler = () => {
    auth.logout();
    history.push("/login");
  };

  return (
    <div className="navigation">
      <ul className="nav-links">
        <li onClick={closeDrawer}>
          <NavLink className="first" to="/" exact>
            Home
          </NavLink>
        </li>
        <li onClick={closeDrawer}>
          <NavLink to="/reports" exact>
            Reports
          </NavLink>
        </li>
        <li
          onClick={() => {
            props.setShowSelectCompanyModal(true);
            if (closeDrawer) {
              closeDrawer();
            }
          }}
        >
          <NavLink to="/projects" exact>
            Projects
          </NavLink>
        </li>
        <li onClick={closeDrawer}>
          <ScrollUp>
            <NavLink to="/aboutus" exact>
              About Us
            </NavLink>
          </ScrollUp>
        </li>
        <li onClick={closeDrawer}>
          <NavLink to="/mission" exact>
            Mission
          </NavLink>
        </li>

        {!auth.isLoggedIn && (
          <li onClick={closeDrawer}>
            <NavLink to="/login" exact>
              Log in
            </NavLink>
          </li>
        )}
        {auth.isLoggedIn &&
          (isMobile ? (
            <li>
              <button onClick={logoutHandler}>Log out</button>
            </li>
          ) : (
            <li>
              <NavDropdown closeDrawerHandler={props.closeDrawerHandler} />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NavLinks;

import React, { useContext } from "react";

import Modal from "./Modal";
import Button from "../FormElements/Button";
import { AuthContext } from "../context/auth-context";
import { useHistory } from "react-router";

const ErrorModal = (props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  let message = props.error;
  switch (props.error) {
    case "jwt expired":
      message = "Session expired. Please log in again.";
      break;
    default:
  }

  return (
    <Modal
      error
      onCancel={props.onClear}
      header="An Error Occurred!"
      show={!!props.error}
      footer={
        <Button
          onClick={() => {
            if (props.error !== "jwt expired") {
              props.onClear();
              if (props.pushTo) {
                history.push(props.pushTo);
              }
            } else {
              auth.logout();
              history.push("/login");
            }
          }}
        >
          Okay
        </Button>
      }
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ErrorModal;

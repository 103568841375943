import React from "react";
import "../pages/Projects.css";

const CANEricSiteUsers = (props) => {
  const {
    filteredSiteUserList,
    completeStatus,
    OngoingStatus,
    navigateToReportHandler,
    isLoading

  } = props;
  return (
    <div className="project-cards-wrap">
            {filteredSiteUserList.length === 0 && !isLoading && <h2>No Results</h2>}

      {filteredSiteUserList &&
        filteredSiteUserList.map((siteUser) => (
          <div className="project-card__container">
            <div
              className={`project-card ${
                siteUser.reportId && "project-card__has-report"
              }`}
              onClick={() => {
                if (siteUser.reportId) {
                  navigateToReportHandler(siteUser.reportId);
                }
              }}
            >
              <div className="project-card-top">
                <div className="site-user__name">
                  {siteUser.logo ? (
                    <img src={siteUser.logo} alt={siteUser.name} />
                  ) : (
                    siteUser.name
                  )}
                </div>
              </div>
              <hr className="project-card-split"></hr>

              <div className="project-card-bottom">
                <div className="project-card-bottom-wrap">
                  
                  <div className="site-user__description">
                    {siteUser.description}
                  </div>
                  <div className="site-user-backbottom backbottom-caneric">
                    <div className="location-framework">
                      {/* <div className="site-user__location">
                        {siteUser.location}
                      </div>
                      &#x2022;
                      <div className="site-user__framework">
                        {siteUser.framework}
                      </div> */}
                    </div>
                    <div className="site-user__status">
                      {siteUser.status === "Complete" ? (
                        <img
                          className="project-status__icon"
                          src={completeStatus}
                          alt="Complete"
                        />
                      ) : (
                        <img
                          className="project-status__icon"
                          src={OngoingStatus}
                          alt="Ongoing"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CANEricSiteUsers;

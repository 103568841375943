export const getUserMemberRole = (memberList, userId) => {
  // let member = props.userList.filter((m) => {
  //   console.log(m.user.id === auth.userId);
  //    return m.user.id === auth.userId;
  //   });
  let member;

  if (memberList.length > 0) {
    member = memberList.find((member) => member.member.id === userId);
    return member.memberRole.roleName;
  } else {
    return "Member";
  }
};

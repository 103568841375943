import React, { useRef, useState, useContext, useEffect } from "react";
import AttachmentsBox from "../../../reports/components/Project/AttachmentsBox";
import axios from "axios";

import "./AttachmentsUpload.css";
import Button from "./Button";
import { AuthContext } from "../context/auth-context";

const AttachmentsUpload = (props) => {
  const { loadedProject, userMemberRole } = props;
  const AxiosController = new AbortController();
  const [newFiles, setNewFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);

  const inputRef = useRef();

  const auth = useContext(AuthContext);

  const token = auth.token;
  // const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const [dragActive, setDragActive] = React.useState(false);

  const [fileUpload, setFileUpload] = useState(null);

  const fetchAttachment = async (pid, fid, fileName) => {
    let res = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/projects/${pid}/files/${fid}`,
      method: "GET",
      responseType: "blob",
      signal: AxiosController.signal,
      headers: {
        Authorization: "token " + token,
      },
    });
    const file = new File([res.data], fileName, { type: res.data.type });

    return file;
  };

  const displayProjectAttachments = async (attachments, loadedProject) => {
    let fileArray = [];
    for (const attachment in attachments) {
      console.log("TEST");

      let file = await fetchAttachment(
        loadedProject.id,
        attachments[attachment]._id,
        attachments[attachment].fileName
      );
      fileArray.push({ file, fid: attachments[attachment]._id });
    }
    props.onInput(
      "existingAttachments",
      newFiles.concat(fileArray.map((item) => item.fid)),
      true
    );
    setExistingFiles(fileArray);
    setIsLoading(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (loadedProject) {
      setIsLoading(true);
      displayProjectAttachments(
        loadedProject.contents.attachments,
        loadedProject
      );
      // for (const attachment in loadedProject.contents.attachments) {
      //   console.log(loadedProject.contents.attachments[attachment].fileName);
      //   fetchAttachment(
      //     loadedProject.id,
      //     loadedProject.contents.attachments[attachment]._id,
      //     loadedProject.contents.attachments[attachment].fileName
      // ); .then((res) => {
      //   // let name = fetchName(
      //   //   loadedProject.contents.attachments[attachment].toString()
      //   // );
      //   // console.log(name);
      //   const file = new File(
      //     [res.data],
      //     loadedProject.contents.attachments[attachment].fileName,
      //     { type: res.data.type }
      //   );

      //   fileArray.push(file);
      // });
      //}
    }
    // console.log(fileArray);
    // setFiles(files.concat(fileArray));
    // props.onInput(props.id, files.concat(fileArray), true);
  }, [loadedProject]);
  //TODO - update to s3
  const removeAttachmentHandler = async (item, fid, indexToRemove) => {
    console.log(item);
    console.log(fid);
    console.log(existingFiles);
    let filteredFiles = existingFiles.filter((item) => item.fid !== fid);
    props.onInput(
      "existingAttachments",
      filteredFiles.map((item) => item.fid),
      true
    );
    setExistingFiles(filteredFiles);
    // if (setNewFiles) {
    //   setNewFiles(newFiles.filter((item, index) => index !== indexToRemove));
    // }
    // const responseData = await sendRequest(
    //   `${process.env.REACT_APP_BACKEND_URL}/projects/${props.pid}/attachment`,
    //   "DELETE",
    //   JSON.stringify({ attachment: attachmentToRemove }),
    //   { Authorization: "Bearer " + token, "Content-Type": "application/json" }
    // );

    // props.setLoadedProject(responseData.project);
  };

  const pickedHandler = (event) => {
    let fileIsValid = isValid;
    let fileList = newFiles.copyWithin();
    if (event.target.files && event.target.files.length > 0) {
      for (let x = 0; x < event.target.files.length; x++) {
        let file = event.target.files[x];
        fileList = fileList.concat(file);
      }
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    setNewFiles(fileList);
    props.onInput(props.id, fileList, fileIsValid);
  };

  const clearFilesHandler = () => {
    setNewFiles([]);
    props.onInput(props.id, [], false);
  };

  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  // const submitFiles = () => {
  //   const formData = new FormData();
  //   files.forEach((file) => {
  //     formData.append("file", file);
  //   });
  //   axios.post(`${uploadAPI}`, formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: "token " + token,
  //     },
  //     onUploadProgress: (p) => {
  //       const percentCompleted = Math.round((p.loaded * 100) / p.total);
  //       setFileUpload({ fileName: files[0].name, percentCompleted });
  //       console.log(p);
  //       console.log(`${percentCompleted}% uploaded`);
  //     },
  //   });
  // };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let fileArray = Array.from(e.dataTransfer.files);
      setNewFiles(newFiles.concat(fileArray));
      let fileIsValid = false;

      let fileList = fileArray.copyWithin();
      if (fileArray && fileArray.length > 0) {
        for (let x = 0; x < fileArray.length; x++) {
          let file = fileArray[x];
          fileList = fileList.concat(file);
        }
        fileIsValid = true;
      } else {
        fileIsValid = false;
      }

      props.onInput(props.id, newFiles.concat(fileArray), fileIsValid);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setNewFiles(newFiles.concat(Array.from(e.target.files)));

      let fileIsValid = false;
      let fileList = newFiles.copyWithin();
      if (e.target.files && e.target.files.length > 0) {
        for (let x = 0; x < e.target.files.length; x++) {
          let file = e.target.files[x];
          fileList = fileList.concat(file);
        }
        fileIsValid = true;
      } else {
        fileIsValid = false;
      }
      props.onInput(
        props.id,
        newFiles.concat(Array.from(e.target.files)),
        fileIsValid
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        gap: "1rem",
      }}
      className="attachments-wrap"
    >
      <div className="attachments-file-upload">
        <form id="form-file-upload" onDragEnter={handleDrag}>
          <input
            ref={inputRef}
            type="file"
            class="attachments__input"
            multiple={true}
            onChange={handleChange}
            hidden
          />

          <label
            id="attachments__input-label"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <p>Drag and drop your file here or</p>
            <Button
              type="button"
              className="upload-button"
              onClick={onButtonClick}
            >
              Upload a file
            </Button>
          </label>
          {/**
           * If the dragging listeners are added to the form, hovering over input elements while dragging will trigger an
           * onDragLeave event.
           * This invisible div will cover the whole form to prevent above issue.
           */}
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>

        <Button
          style={{ marginBottom: "10px" }}
          type="button"
          onClick={clearFilesHandler}
        >
          Clear
        </Button>
      </div>
      <AttachmentsBox
        pid={loadedProject && loadedProject.id}
        type="input"
        newItems={Array.from(newFiles)}
        existingItems={existingFiles}
        userMemberRole={userMemberRole}
        setNewFiles={setNewFiles}
        newFiles={newFiles}
        existingFiles={existingFiles}
        setExistingFiles={setExistingFiles}
        isLoading={isLoading}
        removeAttachmentHandler={removeAttachmentHandler}
      />
    </div>
  );
};
export default AttachmentsUpload;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../shared/components/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { useForm } from "../../../shared/hooks/form-hook";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { uniqueId } from "lodash";

import "./AddMembers.css";
import Input from "../../../shared/components/FormElements/Input";
import Modal from "../../../shared/components/UIElements/Modal";
import Button from "../../../shared/components/FormElements/Button";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

const AddMembers = (props) => {
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [showAddConfirm, setShowAddConfirm] = useState(false);
  const [userToAdd, setUserToAdd] = useState();
  const [formState, inputHandler] = useForm(
    {
      searchCriteria: { value: "", isValid: false },
      isValid: false,
    },
    true
  );
  const token = auth.token;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users`,
          "POST",
          JSON.stringify({
            searchCriteria: formState.inputs.searchCriteria.value,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        let list = [...responseData.users];
        props.userList.map((u) => {
          return (list = list.filter((item) => {
            return item.id !== u.member.id;
          }));
        });
        setLoadedUsers(list);
      } catch (err) {
        console.log(err);
      }
    };
    // if (token) {
    //fetch projects only if token exists. ANother addition to security
    fetchUsers();
    // }
  }, [sendRequest, token, props.userList, formState.inputs.searchCriteria]);
  const openAddConfirmHandler = () => {
    setShowAddConfirm(true);
  };
  const closeAddConfirmHandler = () => {
    setShowAddConfirm(false);
    setUserToAdd();
  };
  return (
    <div className="add-members">
      {isLoading && <LoadingSpinner />}
      <ErrorModal error={error} onClear={clearError} />

      <Input
        element="input"
        id="searchCriteria"
        value=""
        onInput={inputHandler}
        validators={[]}
      />
      {!isLoading && (
        <div className="add-member-view-list">
          <Modal
            show={showAddConfirm}
            header="Add this user as a Member"
            contentClass="show-add-confirm-modal__content"
            footerClass="show-add-confirm-modal__footer"
            footer={
              <React.Fragment>
                <Button
                  onClick={() => {
                    closeAddConfirmHandler();
                    props.addMemberHandler(userToAdd);
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={closeAddConfirmHandler}>Cancel</Button>
              </React.Fragment>
            }
          ></Modal>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Company</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadedUsers.map((user) => (
                  <TableRow
                    key={user ? user.name : uniqueId()}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">{user.company}</TableCell>
                    <TableCell align="right">
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setUserToAdd(user);
                            openAddConfirmHandler();
                          }}
                        >
                        Add Member
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default AddMembers;

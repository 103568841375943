import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer-content">
      <p className="footer-text">
        {/* 3535 Research Road NW Calgary Alberta T2L 1Y1
        <br /> Email: nicoleanne.calma@ucalgary.ca
        <br /> */}
        <br /> Copyright © 2021 CERIN
      </p>
    </div>
  );
};

export default Footer;

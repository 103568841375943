import "./KeywordListForm.css";
import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useForm } from "../../../shared/hooks/form-hook";
import Input from "../../../shared/components/FormElements/Input";
import { uniqueId } from "lodash";

const KeywordListForm = (props) => {
  const [formState, inputHandler] = useForm(
    {
      keywordValue: { value: "", isValid: false },
    },
    false
  );

  const keywordList = props.formState.inputs.keywords? props.formState.inputs.keywords.value : [];

  const addKeywordHandler = (event) => {
    event.preventDefault();
    if (formState.inputs.keywordValue.value.toString().replace(/\s/g, "")) {
      props.inputHandler(
        "keywords",
        keywordList.concat(formState.inputs.keywordValue.value),
        true
      );
    }
  };

  const removeKeywordHandler = (keywordIndex) => {
    props.inputHandler(
      "keywords",
      keywordList.filter((current, index) => keywordIndex !== index),
      true
    );
  };

  return (
    <form className="keyword-list-form">
      <h1>Keywords</h1>
      <div className="keyword-list-input">
        <Input
          element="input"
          id="keywordValue"
          value=""
          onInput={inputHandler}
          validators={[]}
          maxLength={50}
          className="keyword-value-input"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addKeywordHandler(e);
            }
          }}
        />
        <button
          type="button"
          onClick={addKeywordHandler}
          disabled={!formState.inputs.keywordValue.value}
        >
          Add Keyword
        </button>
      </div>
      <div className="keyword-list">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Keyword</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keywordList.map((keyword, index) => (
                <TableRow
                  key={uniqueId()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {keyword}
                  </TableCell>
                  <TableCell align="right">
                    <button
                      type="button"
                      onClick={() => {
                        removeKeywordHandler(index);
                      }}
                    >
                      x
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </form>
  );
};

export default KeywordListForm;

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import MainNavigation from "./shared/components/Navigation/MainNavigation";

import "./App.css";
import React, { Suspense, useState } from "react";
import Footer from "./shared/components/Footer/Footer";
import Auth from "./auth/pages/Auth";
import { AuthContext } from "./shared/components/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { isMobile } from "react-device-detect";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import ViewProjectEdit from "./reports/pages/ViewProjectEdit";
import Aboutus from "./about/pages/Aboutus";
import Test from "./test/test";
import SiteUsers from "./site-users/pages/SiteUsers";
import Projects from "./projects/pages/Projects";

const Landing = React.lazy(() => import("./landing/pages/Landing"));
const Account = React.lazy(() => import("./account/pages/Account"));
const ViewProjectReview = React.lazy(() =>
  import("./reports/pages/ViewProjectReview")
);
const ViewPublishedProject = React.lazy(() =>
  import("./reports/pages/ViewPublishedProject")
);
const MyProjects = React.lazy(() => import("./reports/pages/MyProjects"));
const Admin = React.lazy(() => import("./admin/pages/Admin"));
const AddProject = React.lazy(() => import("./reports/pages/AddProject"));
const Reports = React.lazy(() => import("./reports/pages/Projects"));
const CjLab = React.lazy(() => import("./landing/pages/CjLab"));
const Mission = React.lazy(() => import("./mission/pages/Mission.js"));

const App = () => {
  const { token, login, logout, userId, userName, userRole } = useAuth();
  let routes;
  const [currentPage, setCurrentPage] = useState("Landing");

  const [showSelectCompanyModal, setShowSelectCompanyModal] = useState(true);

  if (token) {
    //if logged in
    if (isMobile) {
      //if logged in mobile
      routes = (
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          {/* <Route path="/test" exact>
            <Test />
          </Route> */}
          <Route path="/projects" exact>
            <Projects
              showSelectCompanyModal={showSelectCompanyModal}
              setShowSelectCompanyModal={setShowSelectCompanyModal}
            />
          </Route>
          <Route path="/reports" exact>
            <Reports />
          </Route>
          <Route path="/mission" exact>
            <Mission />
          </Route>
          <Route path="/site-users" exact>
            <SiteUsers />
          </Route>
          <Route path="/aboutus" exact>
            <Aboutus />
          </Route>
          <Route path="/reports/:pid" exact>
            <ViewPublishedProject />
          </Route>
          <Redirect to="/" />
        </Switch>
      );
    } else {
      //logged in not mobile
      routes = (
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          {/* <Route path="/test" exact>
            <Test />
          </Route> */}
          <Route path="/aboutus" exact>
            <Aboutus />
          </Route>
          <Route path="/projects/review/:pid" exact>
            <ViewProjectReview />
          </Route>
          <Route path="/projects/edit/:pid" exact>
            <ViewProjectEdit />
          </Route>
          <Route path="/projects" exact>
            <Projects
              showSelectCompanyModal={showSelectCompanyModal}
              setShowSelectCompanyModal={setShowSelectCompanyModal}
            />
          </Route>
          <Route path="/reports" exact>
            <Reports />
          </Route>
          <Route path="/reports/:pid" exact>
            <ViewPublishedProject />
          </Route>
          <Route path="/myprojects" exact>
            <MyProjects />
          </Route>
          <Route path="/myprojects/add" exact>
            <AddProject />
          </Route>
          <Route path="/account" exact>
            <Account />
          </Route>
          <Route path="/mission" exact>
            <Mission />
          </Route>
          <Route path="/lab" exact>
            <CjLab />
          </Route>
          <Route path="/site-users" exact>
            <SiteUsers />
          </Route>

          {userRole === "Administrator" ? (
            <Route path="/admin" exact>
              <Admin />
            </Route>
          ) : (
            ""
          )}
          <Redirect to="/" />
        </Switch>
      );
    }
  } else {
    if (isMobile) {
      //Not logged in, in Mobile
      routes = (
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          {/* <Route path="/test" exact>
            <Test />
          </Route> */}
          <Route path="/projects" exact>
            <Projects
              showSelectCompanyModal={showSelectCompanyModal}
              setShowSelectCompanyModal={setShowSelectCompanyModal}
            />
          </Route>
          <Route path="/reports" exact>
            <Reports />
          </Route>
          <Route path="/mission" exact>
            <Mission />
          </Route>
          <Route path="/aboutus" exact>
            <Aboutus />
          </Route>
          <Route path="/reports/:pid" exact>
            <ViewPublishedProject />
          </Route>
          <Route path="/login" exact>
            <Auth />
          </Route>

          <Redirect to="/" />
        </Switch>
      );
    } else {
      //Not logged in or in mobile
      routes = (
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          {/* <Route path="/test" exact>
            <Test />
          </Route> */}
          <Route path="/projects" exact>
            <Projects
              showSelectCompanyModal={showSelectCompanyModal}
              setShowSelectCompanyModal={setShowSelectCompanyModal}
            />
          </Route>
          <Route path="/reports" exact>
            <Reports />
          </Route>
          <Route path="/aboutus" exact>
            <Aboutus />
          </Route>
          <Route path="/reports/:pid" exact>
            <ViewPublishedProject />
          </Route>
          <Route path="/mission" exact>
            <Mission />
          </Route>
          <Route path="/site-users" exact>
            <SiteUsers />
          </Route>
          <Route path="/login" exact>
            <Auth />
          </Route>
          <Route path="/lab" exact>
            <CjLab />
          </Route>
          <Redirect to="/" />
        </Switch>
      );
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
        userName: userName,
        userRole: userRole,
      }}
    >
      <Router>
        <MainNavigation setShowSelectCompanyModal={setShowSelectCompanyModal} />
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
};
export default App;
